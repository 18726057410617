import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table, Modal, Form, Pagination, Stack, Spinner, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import SparkApi from "../api/SparkApi";
import { useLocation } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import moment from "moment";
import Path from "./common/Path";
import jwt_decode from "jwt-decode";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import CsvDownload from "react-json-to-csv";
import CSVUpload, { UnderwriterCSVUpload } from "./common/CSVUpload";

const LeadList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [tempdata, settempdata] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [leadStatusArray, setLeadStatusArray] = useState([
    { value: "Open - Not Contacted", label: "Open - Not Contacted", sortOrder: 1 },
    { value: "Work in Progress", label: "Work in Progress", sortOrder: 2 },
    { value: "Details Awaited", label: "Details Awaited", sortOrder: 3 },
    { value: "Proposal on Hold", label: "Proposal on Hold", sortOrder: 4 },
    { value: "RFQ in Progress", label: "RFQ in Progress", sortOrder: 5 },
    { value: "RFQ Sent to UW", label: "RFQ Sent to UW", sortOrder: 6 },
    { value: "Query Raised by Underwriter", label: "Query Raised by Underwriter", sortOrder: 7 },
    { value: "RFQ Reassigned to Underwriter", label: "RFQ Reassigned to Underwriter", sortOrder: 8 },
    { value: "Quote Received", label: "Quote Received", sortOrder: 9 },
    { value: "Quote Submitted to Client", label: "Quote Submitted to Client", sortOrder: 10 },
    { value: "Revert Pending from Client", label: "Revert Pending from Client", sortOrder: 11 },
    { value: "Closed - Converted", label: "Closed - Converted", sortOrder: 12 },
    { value: "Closed - Not Converted", label: "Closed - Not Converted", sortOrder: 13 },
    { value: "Closed - Partial Converted", label: "Closed - Partial Converted", sortOrder: 14 }
  ]);


  const [leadstatusarr, setleadstatusarr] = useState([
    { value: 'Open Leads', label: 'Open Leads' },
    { value: 'Work in Progress', label: 'Work in Progress' },
    { value: 'Require RM attention', label: 'Require RM attention' },
    { value: 'RFQ with UW', label: 'RFQ with UW' },
    { value: 'Quote sent to Client', label: 'Quote sent to Client' },
    { value: 'Closed Lead', label: 'Closed Lead' },
    { value: 'Non-Closed Lead', label: 'Non-Closed Lead' },
  ]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [userInfo, setUserInfo] = useState(jwt_decode(localStorage.getItem("token")));
  const [auditTrialData, setAuditData] = useState([]); //Audit Trail (Phase-2 Work)

  useEffect(() => {

    // fetchWhatsappAuthorization()
  }, []);


  const fetchWhatsappAuthorization = async () => {
    let result = await SparkApi.authorizations();
    // console.log('result token ', result)
  }


  async function fetchData() {
    const leads = await SparkApi.fetchLead();
    // console.log('leads', leads);
    if (leads) {
      setBody(leads);
      settempdata(leads);
    }
  }

  useEffect(() => {
    fetchData();
  }, [userInfo]);



//Audit Trail (Added By Farhan Khan on 22-Oct-2024)
useEffect(() => {
  fetchDataAudit();
}, []);


async function fetchDataAudit(lead) {
  console.log("Lead id for Audit", lead);
  const auditData = await SparkApi.fetchAuditTrailRecordsByUWId(lead.id);
  if (auditData) {
    setAuditData(auditData);
  }
}
//End Here by Farhan Khan on 22-Oct-2024


  const toggleRowDetails = (rowId) => {
    // console.log('rowid', rowId)
    setExpandedRow(expandedRow === rowId ? null : rowId);
  };

  const getStatusClass = (status) => {
    let accessStatusRec = leadStatusArray?.filter((value) => value.label === status);
    if (accessStatusRec && accessStatusRec.length > 0) {
      switch (accessStatusRec[0].value) {
        case 'Open - Not Contacted':
        case 'Work in Progress':
        case 'Details Awaited':
        case 'Proposal on Hold':
        case 'RFQ in Progress':
          return 'primary'; // Blue
        case 'RFQ Reassigned to Underwriter':
        case 'RFQ Sent to UW':
          return 'warning'; // Yellow
        case 'Query Raised by Underwriter':
        case 'Quote Received':
        case 'Revert Pending from Client':
        case 'Quote Submitted to Client':
          return 'warning'; // Yellow
        case 'Closed - Not Converted':
          return 'danger'; // Red
        case 'Closed - Converted':
          return 'success'; // Green
        default:
          return 'secondary';
      }
    } else {
      return 'secondary';
    }
  };


  const createLead = () => {
    navigate(`/leads/e`);
  };

  const handleLeadEdit = (row) => {
    // console.log('row ==> ', row)
    navigate(`/leads/${row.id}/e`, { state: row });
  }

  const handleViewInsuranceData = (lead) => {
    setSelectedLead(lead);
    setShowModal(true);
    fetchDataAudit(lead); // Added by Farhan Khan to Track Excute Audit Trial API on 23-Oct-2024.
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const [pathdata, setpathdata] = useState({});
  const [showpath, setshowpath] = useState(false);

  const statusOptions = [
    { label: "Quote Preparation in Progress", sortOrder: 1 },
    { label: "Quote Sent to Insurance Company", sortOrder: 2 },
    { label: "Data Insufficient", sortOrder: 3 },
    { label: "Quote/Reply Received from Insurance Company", sortOrder: 4 },
  ];


  const statusOptionsrisk = [
    { label: "Quote Preparation in Progress", sortOrder: 1 },
    { label: "Quote Sent to Insurance Company", sortOrder: 2 },
    { label: "Quote/Reply Received from Insurance Company", sortOrder: 3 },
    { label: "Risk Inspection/Query", sortOrder: 4 },
    { label: "Quote Sent to RM", sortOrder: 5 },
    { label: "Data Insufficient", sortOrder: 6 },
    // New Change :  added new status 28 OCT 2024 (this Array is used in the path component for show status path)
    { label: "Quote Resent to RM", sortOrder: 7 }//New status
  ];



  const handleShowPath = (product) => {
    // console.log('product',product, product['under_writing_status']);
    if (product['risk_inspection'] === 'No') {
      const matchingOption = statusOptionsrisk.find((option) => option.label === product['under_writing_status']);
      // console.log('matchingOption if', matchingOption);
      if (matchingOption) {
        matchingOption.values = statusOptionsrisk;
        setpathdata(matchingOption);
        setshowpath(true);
      }
    }


    if (product['risk_inspection'] === 'Yes') {
      const matchingOption = statusOptionsrisk.find((option) => option.label === product['under_writing_status']);
      // console.log('matchingOption if', matchingOption);
      if (matchingOption) {
        matchingOption.values = statusOptionsrisk;
        setpathdata(matchingOption);
        setshowpath(true);
      }
    }



    if (product['under_writing_status'] === 'Quote Sent to RM') {
      const matchingOptionFallback = statusOptionsrisk.find((option) => option.label === product['under_writing_status']);
      // console.log('matchingOption if ===>', matchingOptionFallback);

      if (matchingOptionFallback) {
        matchingOptionFallback.values = statusOptionsrisk;
        setpathdata(matchingOptionFallback);
        setshowpath(true);
      }
    }

    if (product['under_writing_status']) {
      const matchingOptionFallback = statusOptions.find((option) => option.label === product['under_writing_status']);
      // console.log('matchingOption if ===>', matchingOptionFallback);

      if (matchingOptionFallback) {
        matchingOptionFallback.values = statusOptions;
        setpathdata(matchingOptionFallback);
        setshowpath(true);
      }
    } else {
      toast.error("Product Path is not available for check this path you need to fill Underwriting Status", {
        position: 'top-center',
      });
      setshowpath(false);
    }

  };


  const handleLeadView = (selectedLead) => {
    navigate(`/leads/${selectedLead.id}`, { state: selectedLead });
  }
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    // console.log('query', query);
    if (query) {
      const filteredList = tempdata.filter((item) => {
        for (let key in item) {
          if (typeof item[key] === 'string' && item[key].toLowerCase().includes(query)) {
            return true;
          }
        }
        if (Array.isArray(item?.data)) {
          for (let dataItem of item?.data) {
            for (let key in dataItem) {
              if (typeof dataItem[key] === 'string' && dataItem[key].toLowerCase().includes(query)) {
                return true;
              }
            }
          }
        }
        // Additional condition to filter based on createddate
        if (item.createddate && moment(item.createddate).format('DD-MM-YYYY').includes(query)) {
          return true;
        }
        return false;
      });

      // console.log('updatedList', filteredList);
      setBody(filteredList);
    } else {
      setBody(tempdata);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [leadsPerPage] = useState(10);
  const [currentLeads, setCurrentLeads] = useState([]);

  useEffect(() => {
    const totalPages = Math.ceil(tempdata.length / leadsPerPage);
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
    const indexOfLastLead = currentPage * leadsPerPage;
    const indexOfFirstLead = indexOfLastLead - leadsPerPage;
    const newCurrentLeads = body.slice(indexOfFirstLead, indexOfLastLead);
    setCurrentLeads(newCurrentLeads);

  }, [currentPage, leadsPerPage, body]);

  useEffect(() => {
    setCurrentPage(1);
  }, [body]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(body.length / leadsPerPage);
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;


  const onfilterCount = (event) => {
    // console.log('event', event.target.value)
    const countValue = parseInt(event.target.value);
    // console.log('countValue', countValue)
    // console.log('countValue==>',body.slice(0, countValue))
    setCurrentLeads(body.slice(0, countValue));
    setCurrentPage(1)
    const newTotalPages = Math.ceil(body.length / countValue);
    // console.log('newTotalPages',newTotalPages)
  }


  const onFilterType = (event) => {

    if (event.target.value === 'Open Leads') {
      let filterdata = tempdata.filter((value, index, array) => value.leadstatus === 'Open - Not Contacted');
      setBody(filterdata);
    } else if (event.target.value === 'Work in Progress') {
      let filterdata = tempdata.filter((value, index, array) => value.leadstatus === 'Work in Progress' || value.leadstatus === 'Details Awaited' || value.leadstatus === 'Proposal on Hold' || value.leadstatus === 'RFQ in Progress');
      setBody(filterdata);
    } else if (event.target.value === 'RFQ with UW') {
      let filterdata = tempdata.filter((value, index, array) => value.leadstatus === 'RFQ Sent to UW' || value.leadstatus === 'RFQ Reassigned to Underwriter');
      setBody(filterdata);
    } else if (event.target.value === 'Require RM attention') {
      let filterdata = tempdata.filter((value, index, array) => value.leadstatus === 'Query Raised by Underwriter' || value.leadstatus === 'Quote Received');
      setBody(filterdata);
    } else if (event.target.value === 'Quote sent to Client') {
      let filterdata = tempdata.filter((value, index, array) => value.leadstatus === 'Quote Submitted to Client' || value.leadstatus === 'Revert Pending from Client');
      setBody(filterdata);
    } else if (event.target.value === 'Closed Lead') {
      let filterdata = tempdata.filter((value, index, array) => value.leadstatus === 'Closed - Converted');
      setBody(filterdata);
    } else if (event.target.value === 'Non-Closed Lead') {
      let filterdata = tempdata.filter((value, index, array) => value.leadstatus === 'Closed - Not Converted');
      setBody(filterdata);
    } else {
      setBody(tempdata)
    }
  };

  return (
    //Enhanced the UI 
    <Row className="mx-3">
      <ToastContainer />

      <Col lg={12}>
        <Row className="my-4">
          <Col lg={4}>
            <Form className="">
              <Form.Label>Search</Form.Label>
              <Form.Group>
                <Form.Control type="text" onChange={handleSearch} />
              </Form.Group>
            </Form>
          </Col>
          <Col lg={3} className="">
            <Form.Label>Lead Status</Form.Label>
            <Form.Select
              aria-label="Enter status"
              name="leadstatus"
              onChange={onFilterType}
            >
              <option value="">--Select Type--</option>
              {leadstatusarr.map((item, index) => (
                <option value={item.label} key={index}>
                  {item.label}
                </option>
              ))}
            </Form.Select>
          </Col>

          <Col lg={2} className="">

            <Form.Label>List Count</Form.Label>
            <Form.Select
              aria-label="Enter status"
              name="leadlistcount"
              onChange={onfilterCount}
            >
              <option value="">--Select Count--</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>

            </Form.Select>
          </Col>

          <Col className="d-flex justify-content-end align-items-end">
            <Stack direction="horizontal" className="align-items-end" gap={3}  >

              {/* Here we added the Upload CSV Component */}
              <CSVUpload userInfo={userInfo} fetchLeads={fetchData} />

              {/*added Head sales role 18 Sept 2024 @ali */}
              {(userInfo.userrole === 'SUPER_ADMIN' || userInfo.userrole === 'HEAD_SALES' || userInfo.userrole === 'ADMIN_SALES' || userInfo.userrole === 'RELATIONSHIP_MANAGER') &&
                <Button size="sm" variant="outline-primary" onClick={createLead}>
                  New Lead
                </Button>}

            </Stack>
          </Col>
        </Row>
      </Col>

      <Col lg={12} className="my-2">
        {currentLeads && currentLeads.length > 0 ? (
          <Table striped bordered hover responsive>
            <thead>
              <tr>

                <th>Name</th>
                <th>Customer Id</th>
                <th>Lead Status</th>
                <th>Company</th>
                <th>Company Group</th>
                <th>Manager Name</th>
                <th>Relationship Manager</th>
                <th>Created Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentLeads.map((row, index) => (
                <React.Fragment key={row.id}>
                  <tr onClick={() => toggleRowDetails(row.id)}>
                    <td >{expandedRow === row.id ? <i className="fa-solid fa-caret-up" style={{ color: 'rgb(137 139 148)' }} ></i> : <i className="fa-solid fa-caret-down" style={{ color: 'rgb(137 139 148)' }}></i>}<Link> &nbsp;{row.leadname}</Link></td>
                    <td>{row.customerid}</td>
                    <td>
                      <Badge bg={getStatusClass(row.leadstatus)}>
                        {row.leadstatus}
                      </Badge>
                    </td>
                    <td>{row.company}</td>

                    <td>{row.comapnygroup}</td>
                    <td>{row.ownername}</td>
                    <td>{row.rfqname}</td>
                    <td>{moment(row.createddate).format('DD-MM-yyyy')}</td>
                    <td style={{ display: 'flex' }}>
                      <Button variant="danger" onClick={() => handleLeadEdit(row)}>
                        <i className="fas fa-edit"></i>
                      </Button>
                      <Button variant="danger" className="mx-2" onClick={() => handleLeadView(row)}>
                        <i className="fas fa-eye"></i>
                      </Button>
                      {/* New Change : here we added the underwriter CSV upload */}
                      <UnderwriterCSVUpload fetchLeads={fetchData} userInfo={userInfo} lead_id={row.id} lead_name={row.leadname} />

                    </td>
                  </tr>
                  {expandedRow === row.id && row?.data && row?.data?.length > 0 &&  row.data?.some(product => (product && Object.values(product).some(value => value !== ""))) && (
                    <tr>
                      <td colSpan={12}>
                        <Table style={{ backgroundColor: '#a5c1d587' }}>
                          <thead>
                            <tr>
                              <th>Product Quote</th>
                              <th>Product Code</th>
                              <th>Product Name</th>
                              <th>Under Writing Status</th>
                              <th>Risk Inspection</th>
                              <th>Under Writer Manager</th>
                              {/* Added new field in list view 23 Sept 2024 @ali */}
                              <th>Remark</th>
                              <th>Converted</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {row.data.map((product, index) => (
                              <tr key={index}>
                                <td>{product['product_quote']}</td>
                                <td>{product['product_code']}</td>
                                <td>{product['product_name']}</td>
                                <td>{product['under_writing_status']}</td>
                                <td>{product['risk_inspection']}</td>

                                <td>
                                  {product['underwriter_manager']}
                                </td>
                                {/* Added new field in list view 23 Sept 2024 @ali */}
                                <td>{product['remark']}</td>
                                <td>{(product['converted'] ? "Yes" : "No")}</td>


                                <td>
                                  <Button variant="info" onClick={() => handleViewInsuranceData(product)}>
                                    Audit Trail
                                  </Button>
                                  <Button variant="info" onClick={() => handleShowPath(product)}>
                                    Status
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        ) : (
          // <Container fluidclassName="d-flex justify-content-center align-items-center">
          //{/* <Spinner animation="border" variant="secondary" size="lg" /> */}
          // </Container>
          <ShimmerTable row={5} col={8} />
        )}
      </Col>



      <Col lg={12}>
      <Modal show={showModal} onHide={closeModal} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Audit Trail</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* {selectedLead && ( */}
            <Table striped bordered style={{ tableLayout: "fixed" }}>
              <thead>
                <tr>
                  {/* Match the number of columns with body */}
                  <th>Audit Date</th>
                  <th>From Manager</th>
                  <th>To Manager</th>
                  <th>From Status</th>
                  <th>To Status</th>
                  <th>Old Remark</th>
                  <th>New Remark</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(auditTrialData) && auditTrialData.length > 0 ? (
                  auditTrialData.map((audit, index) => (
                    <tr key={index}>
                      {/* Adjust the number of columns to match colSpan in header */}
                      <td>{moment(audit.audit_date).format("DD-MM-YYYY")}</td>
                      <td>{audit.from_underwriter_manager}</td>
                      <td>{audit.to_underwriter_manager}</td>
                      <td>{audit.from_status}</td>
                      <td>{audit.to_status}</td>
                      <td>{audit.old_remark}</td>
                      <td>{audit.new_remark}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6}>No audit data available</td>{" "}
                    {/* Update to match total columns */}
                  </tr>
                )}
              </tbody>
            </Table>

            {/* )} */}
          </Modal.Body>
        </Modal>

        <Modal show={showpath} size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header>
            <Modal.Title>Product Under Writer Status Path</Modal.Title></Modal.Header>
          <Modal.Body>
            <Path
              key={pathdata.label}
              values={pathdata.values}
              selectedValue={pathdata.label}
            />

          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setshowpath(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <div className='mx-3'>
          <Pagination>
            <Pagination.Prev onClick={() => !isFirstPage && paginate(currentPage - 1)} disabled={isFirstPage}>
              Previous
            </Pagination.Prev>
            {Array.from({ length: totalPages }).map((_, index) => {
              // Calculate the range of pages to display
              const startPage = Math.max(1, currentPage - 4);
              const endPage = Math.min(totalPages, startPage + 9);

              // Only render the page item if it's within the range
              if (index + 1 >= startPage && index + 1 <= endPage) {
                return (
                  <Pagination.Item
                    key={index}
                    active={index + 1 === currentPage}
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                );
              }

              // Render ellipsis if it's not the first or last page and is beyond the range
              if (index === 1 || index === totalPages - 2) {
                return <Pagination.Ellipsis key={index} />;
              }

              return null;
            })}
            <Pagination.Next onClick={() => !isLastPage && paginate(currentPage + 1)} disabled={isLastPage}>
              Next
            </Pagination.Next>
          </Pagination>

        </div>

      </Col>

    </Row>
  );
};

export default LeadList;
