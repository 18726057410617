import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import SparkApi from '../../api/SparkApi';
import jwt_decode from "jwt-decode";

const GroupBarChart = () => {
  const [ownerNames, setOwnerNames] = useState([]);
  const [dataSetsArr, setDataSetsArr] = useState([]);
  const [userInfo, setUserInfo] = useState(
    jwt_decode(localStorage.getItem("token"))
  );

  const leadStatusArray = [
    { label: "Open - Not Contacted", sortOrder: 1 },
    { label: "Work in Progress", sortOrder: 2 },
    { label: "Details Awaited", sortOrder: 3 },
    { label: "Proposal on Hold", sortOrder: 4 },
    { label: "RFQ in Progress", sortOrder: 5 },
    { label: "RFQ Sent to UW", sortOrder: 6 },
    { label: "Query Raised by Underwriter", sortOrder: 7 },
    { label: "RFQ Reassigned to Underwriter", sortOrder: 8 },
    { label: "Quote Received", sortOrder: 9 },
    { label: "Quote Submitted to Client", sortOrder: 10 },
    { label: "Revert Pending from Client", sortOrder: 11 },
    { label: "Closed - Converted", sortOrder: 12 },
    { label: "Closed - Not Converted", sortOrder: 13 },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const leads = await SparkApi.fetchLead();
        // console.log('LEADS', leads);
        const counts = {};
        if (userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER') {
          try {
            const fetchUser = await SparkApi.fetchUsersByManagerId(userInfo);
            console.log('FETCH USERS ->', fetchUser);

            const leadDataArray = [];
            // console.log('call');

            leads.forEach(lead => {
              lead.data.forEach(entry => {
                // console.log('entry', entry);
                const productName = entry["Product Name"];
                const productCode = entry["Product Code"];
                const underWritingStatus = entry["Under Writing Status"];
                let underWriterId = '';
                let underWriterName = '';

                if (typeof entry["Under Writer Manager"] === 'string') {
                  try {
                    const underWriterManager = JSON.parse(entry["Under Writer Manager"]);
                    underWriterId = underWriterManager.id;
                    underWriterName = underWriterManager.username;
                  } catch (error) {
                    console.error('Error parsing JSON for Under Writer Manager:', error);
                  }
                } else {
                  console.error('Under Writer Manager is not a string:', entry["Under Writer Manager"]);
                }

                const leadDataObj = {
                  productname: productName,
                  productcode: productCode,
                  underwriterid: underWriterId,
                  underwritername: underWriterName,
                  underwritingstatus: underWritingStatus
                };

                // console.log(leadDataObj);

                // Check if fetchUser array contains an object with matching id
                if (fetchUser.some(user => user.id === underWriterId)) {
                  leadDataArray.push(leadDataObj);
                }
              });
            });

            // console.log('leadDataArray', leadDataArray);

            // Calculate counts of different statuses and owner names
            const counts = leadDataArray.reduce((acc, curr) => {
              acc[curr.underwritername] = acc[curr.underwritername] || {};
              acc[curr.underwritername][curr.underwritingstatus] = (acc[curr.underwritername][curr.underwritingstatus] || 0) + 1;
              return acc;
            }, {});

            const ownerNames = Object.keys(counts);
            const statusLabels = Array.from(new Set(Object.values(counts).flatMap(Object.keys)));

            // Construct datasets based on counts
            const datasets = statusLabels.map(status => ({
              label: status,
              data: ownerNames.map(owner => counts[owner][status] || 0),
              backgroundColor: getRandomColor(status)
            }));

            setOwnerNames(ownerNames);
            setDataSetsArr(datasets);
          } catch (error) {
            console.error('Error:', error);
          }
        }

        // For show the data of Head UW , its admin and UW related to it's admin 20 Sept 2024 @ali
        if (userInfo.userrole === 'HEAD_UNDERWRITING') {
          try {
            // const fetchUser = await SparkApi.fetchUsersByManagerId(userInfo);
            // console.log('FETCH USERS ->', fetchUser);

            const leadDataArray = [];
            // console.log('call');

            leads.forEach(lead => {
              lead.data.forEach(entry => {
                // console.log('entry', entry);
                const productName = entry["Product Name"];
                const productCode = entry["Product Code"];
                const underWritingStatus = entry["Under Writing Status"];
                let underWriterId = '';
                let underWriterName = '';

                if (typeof entry["Under Writer Manager"] === 'string') {
                  try {
                    const underWriterManager = JSON.parse(entry["Under Writer Manager"]);
                    underWriterId = underWriterManager.id;
                    underWriterName = underWriterManager.username;
                  } catch (error) {
                    console.error('Error parsing JSON for Under Writer Manager:', error);
                  }
                } else {
                  console.error('Under Writer Manager is not a string:', entry["Under Writer Manager"]);
                }

                const leadDataObj = {
                  productname: productName,
                  productcode: productCode,
                  underwriterid: underWriterId,
                  underwritername: underWriterName,
                  underwritingstatus: underWritingStatus
                };

                // console.log(leadDataObj);

                // // Check if fetchUser array contains an object with matching id
                // if (fetchUser.some(user => user.id === underWriterId)) {
                leadDataArray.push(leadDataObj);
                // }
              });
            });

            // console.log('leadDataArray', leadDataArray);
            console.log("leadData",leadDataArray)
            // Calculate counts of different statuses and owner names
            const counts = leadDataArray.reduce((acc, curr) => {
              acc[curr.underwritername] = acc[curr.underwritername] || {};
              acc[curr.underwritername][curr.underwritingstatus] = (acc[curr.underwritername][curr.underwritingstatus] || 0) + 1;
              return acc;
            }, {});

            const ownerNames = Object.keys(counts);
            const statusLabels = Array.from(new Set(Object.values(counts).flatMap(Object.keys)));
            console.log("Counts",counts)
            // Construct datasets based on counts
            const datasets = statusLabels.map(status => ({
              label: status,
              data: ownerNames.map(owner => counts[owner][status] || 0),
              backgroundColor: getRandomColor(status)
            }));
            console.log("datasets", datasets);
            setOwnerNames(ownerNames);
            setDataSetsArr(datasets);
          } catch (error) {
            console.error('Error:', error);
          }
        }


        if (userInfo.userrole === 'HEAD_SALES' || userInfo.userrole === 'ADMIN_SALES' || userInfo.userrole === 'RELATIONSHIP_MANAGER') {
          leads.forEach(lead => {
            const { rfqname, leadstatus } = lead;
            if (rfqname && leadstatus) {
              if (!counts[rfqname]) {
                counts[rfqname] = {};
              }
              if (!counts[rfqname][leadstatus]) {
                counts[rfqname][leadstatus] = 0;
              }
              counts[rfqname][leadstatus]++;
            }
          });

          const ownerNames = Object.keys(counts);
          const statusLabels = Array.from(new Set(Object.values(counts).flatMap(Object.keys)));

          const datasets = statusLabels.map(status => ({
            label: status,
            data: ownerNames.map(owner => counts[owner][status] || 0),
            backgroundColor: getRandomColor(status),
          }));

          setOwnerNames(ownerNames);
          setDataSetsArr(datasets);
        }

        if (userInfo.userrole === 'SUPER_ADMIN') {
          try {
            const fetchUser = await SparkApi.fetchUsersByManagerId(userInfo);
            // console.log('FETCH USERS', fetchUser);

            const leadDataArray = [];
            // console.log('call');

            // Extract lead data
            leads.forEach(lead => {
              lead.data.forEach(entry => {
                // console.log('entry', entry);
                const productName = entry["Product Name"];
                const productCode = entry["Product Code"];
                const underWritingStatus = entry["Under Writing Status"];
                const leadStatus = entry["Lead Status"];
                let underWriterId = '';
                let underWriterName = '';

                // Parse Under Writer Manager if it's a string
                if (typeof entry["Under Writer Manager"] === 'string') {
                  try {
                    const underWriterManager = JSON.parse(entry["Under Writer Manager"]);
                    underWriterId = underWriterManager.id;
                    underWriterName = underWriterManager.username;
                  } catch (error) {
                    console.error('Error parsing JSON for Under Writer Manager:', error);
                  }
                } else {
                  console.error('Under Writer Manager is not a string:', entry["Under Writer Manager"]);
                }

                // Create lead data object
                const leadDataObj = {
                  productname: productName,
                  productcode: productCode,
                  underwriterid: underWriterId,
                  underwritername: underWriterName,
                  underwritingstatus: underWritingStatus,
                  leadstatus: leadStatus
                };

                // console.log(leadDataObj);

                // Check if fetchUser array contains an object with matching id
                if (fetchUser.some(user => user.id === underWriterId)) {
                  leadDataArray.push(leadDataObj);
                }
              });
            });

            // console.log('leadDataArray', leadDataArray);

            // Calculate counts of different statuses and owner names
            const counts = leadDataArray.reduce((acc, curr) => {
              acc[curr.underwritername] = acc[curr.underwritername] || {};
              acc[curr.underwritername][curr.underwritingstatus] = (acc[curr.underwritername][curr.underwritingstatus] || 0) + 1;
              acc[curr.underwritername][curr.leadstatus] = (acc[curr.underwritername][curr.leadstatus] || 0) + 1;
              return acc;
            }, {});

            // Incorporating additional logic for leads without underwriters
            leads.forEach(lead => {
              const { ownername, leadstatus } = lead;
              if (ownername && leadstatus) {
                if (!counts[ownername]) {
                  counts[ownername] = {};
                }
                if (!counts[ownername][leadstatus]) {
                  counts[ownername][leadstatus] = 0;
                }
                counts[ownername][leadstatus]++;
              }
            });

            const ownerNames = Object.keys(counts);
            const statusLabels = Array.from(new Set(Object.values(counts).flatMap(Object.keys)));

            // Construct datasets based on counts
            const datasets = statusLabels.map(status => ({
              label: status,
              data: ownerNames.map(owner => counts[owner][status] || 0),
              backgroundColor: getRandomColor(status)
            }));

            // Set owner names and datasets
            setOwnerNames(ownerNames);
            setDataSetsArr(datasets);
          } catch (error) {
            console.error('Error:', error);
          }
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  const getRandomColor = (status) => {
    const randomColor = () => Math.floor(Math.random() * 256);
    const opacity = status === "Closed - Converted" ? 1 : 0.5;
    return `rgb(${randomColor()}, ${randomColor()}, ${randomColor()}, ${opacity})`;
  };

  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: false,
        text: 'Lead Status by RFQ Name',
      },
    },
  };
  // console.log('dataSetsArr')
  const data = {
    labels: ownerNames,
    datasets: dataSetsArr,
  };

  return <Bar options={options} data={data} width={150} height={100} />;
};

export default GroupBarChart;
