/* Start updated by Pooja Vaishnav and it will show particular incident details */

import React, { useState, useEffect } from "react";
import { Badge, Button, Card, Col, Container, Row } from "react-bootstrap";
import Confirm from "./Confirm";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FilesCreate from "./FilesCreate";
import RelatedListFiles from "./RelatedListFiles";
import RelatedListTask from "./RelatedListTask";
import TaskEdit from "./TaskEdit";
import Path from "./common/Path";
import moment from "moment";
import * as constants from "../constants/CONSTANT";
import PubSub from "pubsub-js";
import EmailComposer from "./common/EmailComposer";
import Chat from "./common/Chat";

const IncidentView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [incident, setIncident] = useState(location.state ? location.state : {});
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [relatedListFiles, setRelatedListFiles] = useState(true);
  const [relatedListLeadtTests, setRelatedListLeadtTests] = useState(true);

  const [modalShowFile, setModalShowFile] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [modalShowEmail, setModalShowEmail] = useState(false);
  const [reletedListTask, setReletedListTask] = useState(false);
  const [refreshFileList, setRefreshFileList] = useState();
  const [userName, setUserName] = useState("");
  const [refreshTaskList, setRefreshTaskList] = useState();

  useEffect(() => {
    fetchIncident();
    
  }, []);

  const fetchIncident = () => {
    if (
      !incident.id &&
      location.hasOwnProperty("pathname") &&
      location.pathname.split("/").length >= 3
    ) {
      incident.id = location.pathname.split("/")[2];
    }
    async function initStudent() {
      let result = await SparkApi.fetchIncidetById(incident.id);
      if (result) {
        setIncident(result);
        setData(result);
      } else {
        setIncident({});
      }
    }
    initStudent();
  };

  const deleteIncident = async () => {
    const result = await SparkApi.deleteIncident(incident.id);
    if (result.success) navigate(`/incidents`);
  };
  const editIncident = () => {
    navigate(`/incidents/${incident.id}/e`, { state: incident });
  };

  const handleSelect = (key) => {
    if (key === "tasks") {
      setReletedListTask(true);
      setRelatedListFiles(false);
    } else if (key === "files") {
      setRelatedListFiles(true);
      setReletedListTask(false);
    }
  };

  const submitFiles = () => {
    setModalShowFile(false);
    setRefreshFileList(Date.now());
    setModalShowTask(false);
  };


  const submitTasks = () => {
    setModalShowTask(false);
    setModalShowEmail(false);
    setRefreshTaskList(Date.now());
  };



  return (
    <Container>
      {incident && (
        <>
          {modalShow && (
            <Confirm
              show={modalShow}
              onHide={() => setModalShow(false)}
              deleteIncident={deleteIncident}
              title="Confirm delete?"
              message="You are going to delete the record. Are you sure?"
              table="incident"
            />
          )}
          <Row className="view-form">
            <Col lg={11} className="pb-3 pt-2">
              <Link className="nav-link" to="/incidents">
                Home <i className="fa-solid fa-chevron-right"></i>{" "}
                <div style={{ color: "#23468c", display: "inline" }}>Incidents</div>
              </Link>
            </Col>
            <Col></Col>
          </Row>

          <Row className="view-form gx-5 mx-auto">
            <Col lg={8}>
              <Row className="view-form-header align-items-center">
                <Col lg={8}>
                  Incident
                  <h5>
                    {incident.incidentnumber}
                  </h5>
                </Col>
                <Col lg={4} className="d-flex justify-content-end">
                  <Button
                    className="btn-sm mx-2"
                    onClick={() => editIncident(true)}
                  >
                    <i className="fa-regular fa-pen-to-square"></i>
                  </Button>
                  <Button
                    className="btn-sm mx-2"
                    variant="danger"
                    onClick={() => setModalShow(true)}
                  >
                    Delete
                  </Button>
                  <Button
                    className="float-right btn-sm"
                    onClick={() => setModalShowEmail(true)}
                    variant="success"
                    title="Send Email"
                  >
                    <i className="fa-regular fa-envelope mx-2"></i>
                  </Button>
                </Col>
              </Row>

              <Row className="py-3 ibs-edit-form">
                <Col lg={6}>
                  <label>Incident Number</label>
                  <span>
                    {incident.incidentnumber}
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Subject</label>
                  <span>
                    {incident.subject}
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Priority</label>
                  <span>{incident.priority}</span>
                </Col>
                <Col lg={6}>
                  <label>Status</label>
                  <span>{incident.status}</span>
                </Col>
                <Col lg={6}>
                  <label>Origin</label>
                  <span>{incident.origin}</span>
                </Col>
                <Col lg={6}>
                  <label>Contact</label>
                  <Link to={"/contacts/" + incident.contactid} state={{ id: incident.contact }}>
                    <span>{incident.contactname}</span>
                  </Link>
                </Col>
                <Col lg={6}>
                  <label>Product</label>
                  <Link to={"/products/" + incident.productid} state={{ id: incident.product }}>
                    <span>{incident.productname}</span>
                  </Link>
                </Col>
                <Col lg={6}>
                  <label>Assigned User</label>
                  <span>
                    <Badge
                      bg="warning"
                      style={{ display: "inline", color: "#000" }}
                    >
                      {incident.ownername}
                    </Badge>
                  </span>
                </Col>
                <Col lg={12}>
                  <label>Description</label>
                  <span>{incident.description}</span>
                </Col>
                <Col lg={12}>
                  <label>Created Date</label>
                  <span>{moment(incident.createddate).format("DD MMM, yyyy")}</span>
                </Col>

                <Col className="mt-5"></Col>
              </Row>
            </Col>
            <Col lg={4}>
              <Chat parentid={incident.id} />
            </Col>
          </Row>

          <Card bg="light" text="light" className="mb-2 mt-4">
            <Card.Header className="d-flex justify-content-between">
              <Tabs
                defaultActiveKey="files"
                id="uncontrolled-tab-example"
                onSelect={(key) => handleSelect(key)}
              >
                <Tab eventKey="files" title="Files"></Tab>
                <Tab eventKey="tasks" title="Tasks"></Tab>
              </Tabs>

              {relatedListFiles && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowFile(true)}
                >
                  New File
                </Button>
              )}
              {modalShowFile && (
                <FilesCreate
                  show={modalShowFile}
                  onHide={() => setModalShowFile(false)}
                  parent={incident}
                  table="medicaltest"
                  submitFiles={submitFiles}
                />
              )}

              {reletedListTask && (
                <div>
                  <Button
                    className="float-right btn-sm mx-2"
                    onClick={() => setModalShowTask(true)}
                  >
                    New Task
                  </Button>
                  <Button
                    className="float-right btn-sm"
                    onClick={() => setModalShowEmail(true)}
                    variant="success"
                  >
                    <i className="fa-regular fa-envelope mx-2"></i>
                    Send Email
                  </Button>
                </div>
              )}
              {modalShowTask && (
                <TaskEdit
                  show={modalShowTask}
                  onHide={() => setModalShowTask(false)}
                  parentid={incident?.id}
                  table="lead"
                  submitTasks={submitTasks}
                />
              )}
            </Card.Header>
            <Card.Body>
              {relatedListFiles && (
                <RelatedListFiles
                  refreshFileList={refreshFileList}
                  parent={incident}
                />
              )}
              {reletedListTask ? (
                <RelatedListTask
                  refreshTaskList={refreshTaskList}
                  parent={incident}
                />
              ) : (
                ""
              )}
            </Card.Body>
          </Card>

          <EmailComposer
            size="lg"
            show={modalShowEmail}
            onHide={() => setModalShowEmail(false)}
            parentid={incident?.id}
            toEmail={incident?.email}
            table="incident"
            submitTasks={submitTasks}
          />
        </>
      )}
    </Container>
  );
};
export default IncidentView;
/* End updated by Pooja Vaishnav */
