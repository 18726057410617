/* Start updated by Pooja Vaishnav and it will show particular product details and show related pricebook */

import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Nav, Row } from "react-bootstrap";
import Confirm from "./Confirm";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SparkApi from "../api/SparkApi";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import RelatedListFiles from "./RelatedListFiles";
import FilesCreate from "./FilesCreate";
import PricebookEdit from "./PricebookEdit";
import RelatedListPricebooks from "./RelatedListPriceBook";

import { Link } from "react-router-dom";
import helper from "./common/helper";
import * as constants from '../constants/CONSTANT';

const ProductView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [product, setProduct] = useState(location.state ? location.state : {});
  const [modalShow, setModalShow] = useState(false);
  const [modalShowFile, setModalShowFile] = useState(false);
  const [relatedListPricebook, setRelatedListPricebook] = useState(true);
  const [relatedListFiles, setRelatedListFiles] = useState(false);
  const [refreshFileList, setRefreshFileList] = useState();
  const [modalShowPricebook, setModalShowPricebook] = useState(false);
  const [refreshPricebookList, setRefreshPricebookList] = useState(Date.now());

  useEffect(() => {
    fetchProductById();
  }, []);

  const fetchProductById = () => {
    if (location.hasOwnProperty("pathname")) {
      product.id = location.pathname.split("/")[2];
    }
    async function initProduct() {
      let result = await SparkApi.fetchProduct(product.id);
      if (result) {
        setProduct(result);
      } else {
        setProduct({});
      }
    }
    initProduct();
  };
  //submitPricebooks
  const submitPricebooks = () => {
    setModalShowPricebook(false);
    setRefreshPricebookList(Date.now());
  };
  //deleteproduct
  const deleteproduct = async () => {
    const result = await SparkApi.deleteProduct(product.id);
    if (result.success) navigate(`/products`);
  };

  //editProduct
  const editProduct = () => {
    navigate(`/products/${product.id}/e`, { state: product });
  };
  //submitFiles
  const submitFiles = () => {
    setModalShowFile(false);
    setRefreshFileList(Date.now());
  };

  const handleSelect = (key) => {
    if (key === "pricebooks") {
      setRelatedListPricebook(true);
      setRelatedListFiles(false);
    } else if (key === "files") {
      setRelatedListFiles(true);
      setRelatedListPricebook(false);
    }
  };
  return (
    <div>
      {product && (
        <Container>
          {modalShow && (
            <Confirm
              show={modalShow}
              onHide={() => setModalShow(false)}
              deleteProduct={deleteproduct}
              title="Confirm delete?"
              message="You are going to delete the record. Are you sure?"
              table="product"
            />
          )}
          <Row className="view-form">
            <Col lg={11} className="pb-3 pt-2">
              <Link className="nav-link" to="/products">
                Home <i className="fa-solid fa-chevron-right"></i> <div style={{ color: "#23468c", display: "inline" }}>Products</div>
              </Link>
            </Col>
            <Col></Col>
          </Row>
          <Row className="view-form">
            <Col></Col>
            <Col lg={8}>
              <Row className="view-form-header align-items-center">
                <Col lg={8}>
                  Product
                  <h5>
                    {product.productname}
                  </h5>
                </Col>
                <Col lg={4} className="d-flex justify-content-end">
                  <Button
                    className="btn-sm mx-2"
                    onClick={() => editProduct(true)}
                  >
                    <i className="fa-regular fa-pen-to-square"></i>
                  </Button>
                  {helper.checkPermission(constants.MODIFY_ALL) && <Button
                    className="btn-sm"
                    variant="danger"
                    onClick={() => setModalShow(true)}
                  >
                    Delete
                  </Button>}


                </Col>
              </Row>
              <Row className="py-3 ibs-edit-form">
                <Col lg={6}>
                  <label>Product Name</label>
                  <span>
                    {product.productname}
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Product Code</label>
                  <span>{product.productcode}</span>
                </Col>
                <Col lg={6}>
                  <label>Category</label>
                  <span>{product.category}</span>
                </Col>
                <Col lg={6}>
                  <label>Subcategory</label>
                  <span>{product.subcategory}</span>
                </Col>
                {/* <Col lg={6}>
                  <label>Instock</label>
                  <span>{product.unitcount}</span>
                </Col> */}
                <Col lg={12}>
                  <label>Description</label>
                  <span>{product.description}</span>
                </Col>
              </Row>
            </Col>
            <Col></Col>
          </Row>
          <Card bg="light" text="light" className="mb-2 mt-4">
            <Card.Header className="d-flex justify-content-between">
              <Tabs
                defaultActiveKey="files"
                id="uncontrolled-tab-example"
                onSelect={(key) => handleSelect(key)}
              >
                {/* <Tab eventKey="pricebooks" title="Pricebook"></Tab> */}
                <Tab eventKey="files" title="Files"></Tab>
              </Tabs>
              {/* {relatedListPricebook && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowPricebook(true)}
                >
                  New Pricebook
                </Button>
              )} */}
            
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowFile(true)}
                >
                  New File
                </Button>
              
              {modalShowFile && (
                <FilesCreate
                  show={modalShowFile}
                  onHide={() => setModalShowFile(false)}
                  parent={product}
                  table="medicaltest"
                  submitFiles={submitFiles}
                />
              )}
            </Card.Header>
            <Card.Body>
              {/* {relatedListPricebook && <RelatedListPricebooks parent={product} refreshPricebookList={refreshPricebookList} />} */}

         
                <RelatedListFiles
                  refreshFileList={refreshFileList}
                  parent={product}
                />
              

              {/* {modalShowPricebook && (
                <PricebookEdit
                  show={modalShowPricebook}
                  onHide={() => setModalShowPricebook(false)}
                  parent={product}
                  pricebooks={null}
                  submitPricebooks={submitPricebooks}
                />
              )} */}
            </Card.Body>
          </Card>
        </Container>
      )}
    </div>
  );
};
export default ProductView;
/* End updated by Pooja Vaishnav */
