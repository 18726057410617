import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import SparkApi from "../api/SparkApi";
import { useLocation } from "react-router-dom";

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import authApi from "../api/authApi";
import jwt_decode from "jwt-decode";

const UserList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [leadname, setLeadName] = useState();
  const [userInfo, setUserInfo] = useState(jwt_decode(localStorage.getItem('token')));
  // const[contact,setContact] = useState(location.state ? location.state : {});
  const [lead, setLead] = useState();
  ////////('location.state ='+location.state)

  useEffect(() => {

    async function init() {
      const fetchUser = await SparkApi.fetchUsersByManagerId(userInfo);
      // console.log('FETCH USERS', fetchUser)
      if (fetchUser && Array.isArray(fetchUser)) {
        setBody(fetchUser);
      } else {
        setBody([]);
      }



    }
    init();
  }, []);






  const editUser = (row) => {
    navigate(`/users/${row.row.id}/e`, { state: row.row });
  }
  const header = [
    {
      title: "Name",
      prop: "username",
      isFilterable: true,
      cell: (row) => (
        <Link to={"/users/" + row.id} state={row}>
          {row.firstname} {row.lastname}
        </Link>
      ),
    },
    { title: "Role", prop: "userrole", isFilterable: true },
    { title: "Manager Name", prop: "managername", isFilterable: true },
    { title: "Phone", prop: "phone", isFilterable: true },
    { title: "Email", prop: "email", isFilterable: true },
    {
      title: "Active", prop: "isactive", isFilterable: true,
      cell: (row) => (

        row.isactive === true ? <i className="fa-regular fa-square-check" style={{ fontSize: "1.3rem", marginLeft: "19px" }}></i> : <i className="fa-regular fa-square" style={{ fontSize: "1.3rem", marginLeft: "19px" }}></i>

      )
    },
    {
      title: "Actions",
      prop: "id",
      cell: (row) => (
        <>
          {userInfo.userrole === 'ADMIN_SALES' || userInfo.userrole === 'SUPER_ADMIN' || userInfo.userrole === 'ADMIN_UNDERWRITING' ?
            <Button className="btn-sm mx-2" onClick={() => editUser({ row })}>
             <i className="fas fa-edit"></i>
            </Button>
            : null
          }
        </>

      ),
    },

  ];

  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " ",
  };

  const createUser = () => {
    navigate(`/users/e`);
  };

  return (
    <Row className="g-0">

      <Col lg={12} className="px-4">
        <DatatableWrapper
          body={body}
          headers={header}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 10,
              options: [5, 10, 15, 20],
            },
          }}
        >
          <Row className="mb-4">
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            >
              <PaginationOptions labels={labels} />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              {userInfo.userrole === 'SUPER_ADMIN' ? <Button
                className="btn-sm"
                variant="outline-primary mx-2"
                onClick={() => createUser(true)}
              >
                New User
              </Button> : null}

              {/* <Button className="btn-sm" variant="outline-primary" onClick={()=>location.state ? navigate(`/contacts/${contact.id}`) : navigate('/contacts/')}>Back to Contact</Button> */}
            </Col>
          </Row>
          {body.length > 0 ? (
            <Table striped className="data-table" responsive="sm">
              <TableHeader />
              <TableBody />
            </Table>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}
          <Pagination />
        </DatatableWrapper>
      </Col>
      <Col lg={2}></Col>
    </Row>
  );
};
export default UserList;
