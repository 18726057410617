import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

const BarChart = ({ data }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Count Report',
      },
    },
  };

  let labels = [];
  let countSum = 0;
  let datasets = [];

  if (Array.isArray(data)) {
    labels = data.map((item) => item.label || "");
    countSum = data.reduce((sum, item) => sum + (item.count ? parseFloat(item.count) : 0), 0);

    datasets = [
      {
        label: `Total: ${countSum}`,
        data: data.map((item) => item.count),
        backgroundColor: 'rgba(255, 215, 0, 0.7)',
      },
    ];
  }

  return <Bar options={options} data={{ labels, datasets }} width={100} height={50} />;
};

export default BarChart;
