import React, { useState, useEffect } from "react";
import { Col, Row, Table, Button, Card } from "react-bootstrap";
import BarChart from "../components/charts/BarChart";
import PieChart from "../components/charts/PieChart";
import VerticalChart from "../components/charts/VerticalChart";
import { ListGroup } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import { constrainPoint } from "@fullcalendar/core/internal";

const DashboardView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [dashboard, setDashboard] = useState(
    location.state ? location.state : {}
  );
  const [reportData, setReportData] = useState([]);
  const [reportQueriesData, setReportQueriesData] = useState([]);
  //const [reportName, setReportNames] = useState([]);
  //const [boxId, setBoxIds] = useState([]);
  //const [data, setData] = useState([]);
  //const [reportName1, setReportName1] = useState([]);
  const [reportName1, setReportName1] = useState([]);
  const [querydata, setQueryData] = useState([]);
  const [reportid, setReortId] = useState([]);

  useEffect(() => {
    async function init() {
      fetchDashboard();
    }
    init();
  }, []);



  useEffect(() => {
    if (reportData) {

      let arr = [];
      let brr = [];
      let crr = [];
      reportData?.fetchReports?.forEach((data) => {
        arr[data.boxid - 1] = data.reportdata.name;
      });

      reportData?.reportQueriesData?.forEach((dta) => {
        brr[dta.boxid - 1] = dta.querydata;
      });

      // ////("brr --->", brr);

      reportData?.fetchReports?.forEach((data) => {
        crr[data.boxid - 1] = data.reportdata.id;
      });

      // ////("arr", arr);
      setReportName1([...reportName1, ...arr]);
      setQueryData([...querydata, ...brr]);
      setReortId([...reportid, ...crr]);
      // const [reportid, setReortId] = useState([]);
    }
  }, [reportData]);

  ////("reportName1", reportName1);
  ////("querydata", querydata);
  ////("reportid", reportid);

  const fetchDashboard = async () => {
    ////(dashboard)
    ////("dashboard --------------------->", dashboard);
    ////("dashboard Name --------------------->", dashboard.name);
    ////("dashboard iiiid", dashboard.name);
    const reports = await SparkApi.fetchDashboardById(dashboard);
    ////("reports ===++>>", reports);
    //reports?.fetchReports
    if (reports) {
      setReportData(reports || []);
    } else {
      console.error("No reports or invalid report data received.");
    }
  };
  ////("reportQueriesData jjjjj", reportQueriesData);

  function editDashboard() {

    navigate(`/dashboards/${dashboard}/e`, { state: dashboard });
  }

  //  const editContact = () => {
  //   navigate(`/contacts/${contact.id}/e`, { state: contact });
  // };
  //dashboard/:id/e

  const [barchart, setbarchart] = useState(true);
  const [verticalchart, setverticalchart] = useState(false);
  const [piechart, setpiechart] = useState(false);

  const handleChart = async (reportid) => {
    ////('reportid', reportid)
    navigate(`/reports/${reportid}`)
    // if (chartname === 'Vertical Chart') {
    //   setbarchart(false)
    //   setpiechart(false)
    //   setverticalchart(true);
    // } else if (chartname === 'Pie Chart') {
    //   setbarchart(false)
    //   setpiechart(true);
    //   setverticalchart(false)
    // } else if (chartname === 'Bar Chart') {
    //   setbarchart(true)
    //   setpiechart(false);
    //   setverticalchart(false)
    // }

  }

  return (
    <>
      <Row className="my-3 mx-1">
        <Link className="nav-link my-2" to="/dashboards">
          Home <i className="fa-solid fa-chevron-right"></i> <div style={{ color: "#23468c", display: "inline" }}>Dashboards</div>
        </Link>
        <Col lg={12}>
          <nav
            className="navbar navbar-light"
            style={{ backgroundColor: "#fff" }}
          >
            <Col lg={7}>
              <h5 className="my-2 capitalFields" style={{ marginLeft: "10px" }}>{reportData.name}</h5>
            </Col>
            <Col lg={5} className="d-flex justify-content-end">

              <Button variant="primary" className="mx-3" onClick={editDashboard}>
                <i className="fa-regular fa-pen-to-square"></i>

              </Button>
            </Col>
          </nav>
        </Col>
      </Row>
      <Row className="mx-2">
        {/* <Col lg={2}></Col> */}
        <Col lg={12}>
          <Row>
            <React.Fragment>
              <Col lg={6}>
                <div>
                  <Card>
                    <Card.Header style={{ height: "40px" }}>
                      {Array.isArray(querydata[0]) ? (
                        <Row className="my-2 capitalFields report-name">
                          <Col >{reportName1[0]}</Col>
                          <Col></Col>
                          <Col > {reportName1[0] ? <Link className="mx-5 report-name" to={`/reports/${reportid[1]}`} style={{ color: 'white', textDecoration: 'none' }}>View Report</Link> : ''}</Col>
                        </Row>
                      ) : (
                        <p>{null}</p>
                      )}
                    </Card.Header>
                    <Card.Body style={{ height: "400px" }}>
                      <React.Fragment>
                        {Array.isArray(querydata[0]) ? (
                          <BarChart data={querydata[0]} />
                        ) : (
                          <p>{null}</p>
                        )}
                      </React.Fragment>
                    </Card.Body>
                  </Card>
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <Card>
                    <Card.Header style={{ height: "40px" }}>
                      <Row className="my-2 capitalFields report-name">
                        <Col >{reportName1[1]}</Col>
                        <Col></Col>
                        <Col> {reportName1[1] ? <Link className="mx-5 report-name" to={`/reports/${reportid[1]}`} style={{ color: 'white', textDecoration: 'none' }}>View Report</Link> : ''}</Col>
                      </Row>
                      <b>

                        <h5 >  </h5>
                      </b>
                    </Card.Header>
                    <Card.Body style={{ height: "400px", marginLeft: '-150px' }}>
                      <React.Fragment>
                        {Array.isArray(querydata[1]) ? (
                          <PieChart data2={querydata[1]} />
                        ) : (
                          <p>{null}</p>
                        )}
                      </React.Fragment>
                    </Card.Body>
                  </Card>
                </div>
              </Col>

              <Col lg={6} className="my-4">
                <Card>
                  <Card.Header style={{ height: "40px" }}>
                    <Row className="my-2 capitalFields report-name">
                      <Col >{reportName1[2]}</Col>
                      <Col></Col>
                      <Col> {reportName1[2] ? <Link className="mx-5 report-name" to={`/reports/${reportid[1]}`} style={{ color: 'white', textDecoration: 'none' }}>View Report</Link> : ''}</Col>
                    </Row>

                  </Card.Header>
                  <Card.Body style={{ height: "400px" }}>
                    <React.Fragment>
                      {Array.isArray(querydata[2]) ? (
                        <VerticalChart data1={querydata[2]} />
                      ) : (
                        <p>{null}</p>
                      )}
                    </React.Fragment>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={6} className="my-4">
                <Card>
                  <Card.Header style={{ height: "40px" }}>
                    <Row className="my-2 capitalFields report-name">
                      <Col >{reportName1[3]}</Col>
                      <Col></Col>
                      <Col> {reportName1[3] ? <Link className="mx-5 report-name" to={`/reports/${reportid[1]}`} style={{ color: 'white', textDecoration: 'none' }}>View Report</Link> : ''}</Col>
                    </Row>

                  </Card.Header>
                  <Card.Body style={{ height: "400px" }}>
                    <React.Fragment>
                      {Array.isArray(querydata[3]) ? (
                        <BarChart data={querydata[3]} />
                      ) : (
                        <p>{null}</p>
                      )}
                    </React.Fragment>
                  </Card.Body>
                </Card>
              </Col>
            </React.Fragment>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default DashboardView;
