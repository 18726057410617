/* Start updated by Pooja Vaishnav and it will create new product and updates in the selected 
  product by using product id*/

  import React, { useState, useEffect } from "react";
  import { Col, Container, Row } from "react-bootstrap";
  import Button from "react-bootstrap/Button";
  import Form from "react-bootstrap/Form";
  import { Link, useLocation } from "react-router-dom";
  import { useNavigate } from "react-router-dom";
  import "react-bootstrap-typeahead/css/Typeahead.css";
  import SparkApi from "../api/SparkApi";
  import PubSub from "pubsub-js";
  import * as constants from '../constants/CONSTANT';
  
  const ProductEdit = (props) => {
    const [validated, setValidated] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [product, setProduct] = useState(location.state ? location.state : {});
    const [productCategoryArray, setProductCategoryArray] = useState(JSON.parse(localStorage.getItem('product_category')));
    const [producSubcategoryArray, setProductSubcategoryArray] = useState(JSON.parse(localStorage.getItem('product_subcategory')));
    const productCategory = productCategoryArray;
    const productSubcategory = producSubcategoryArray;
    useEffect(() => {
      if (product.id) {
        let temp = {}
      }
      if (location?.state) {
        let prodCategory = productCategory.filter(status => status.value === location.state.productCategory)
      }
    }, []);
  
    const handleChange = (e) => {
      setProduct({ ...product, [e.target.name]: e.target.value });
    };
  
    const checkRequredFields = () => {
      if (
        product.productname &&
        product.productname.trim() !== "" &&
        product.productname &&
        product.productname.trim() !== "" &&
        product.productcode &&
        product.productcode.trim() !== ""
      ) {
        return false;
      }
      return true;
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (checkRequredFields()) {
        setValidated(true);
        return;
      }
      //========= Logic to perform Create or Edit ======
      let result = {};
      if (product.id) {
        result = await SparkApi.saveProduct(product);
        if (result.success) {
          PubSub.publish("RECORD_SAVED_TOAST", {
            title: "Record Saved",
            message: "Record saved successfully",
          });
          navigate(`/products/${product.id}`, { state: product });
        }
      } else {
        result = await SparkApi.createProduct(product);
        if (result != null) {
          PubSub.publish("RECORD_SAVED_TOAST", {
            title: "Record Saved",
            message: "Record saved successfully",
          });
          navigate(`/products/${result.id}`, { state: result });
        }
      }
    };
  
    const handleCancel = () => {
      if (product.id) {
        navigate("/products/" + product.id, { state: product });
      } else {
        navigate(`/products`)
      }
    };
    return (
      <Container className="view-form">
        <Row >
          <Col></Col>
          <Col lg={8} className="pb-1 pt-2">
            <Link className="nav-link" to="/products">
              Home <i className="fa-solid fa-chevron-right"></i> <div style={{ color: "#23468c", display: "inline" }}>Products</div>
            </Link>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col></Col>
          <Col lg={8}>
            <Form
              className="mt-3"
              onSubmit={handleSubmit}
              noValidate
              validated={validated}
            >
              <Row className="view-form-header align-items-center">
                <Col lg={8}>
                  {product.id ? (
                    <h5>Edit Product</h5>
                  ) : (
                    <h5>Create Product</h5>
                  )}
  
                </Col>
                <Col lg={4} className="d-flex justify-content-end">
                  <Button className="btn-sm mx-2" onClick={handleSubmit}>
                    Save
                  </Button>
                  <Button
                    className="btn-sm"
                    variant="danger"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
              <Row className="ibs-edit-form pb-3 ">
                <Row lg={12}>
                  <Col lg={6}>
                    <Form.Group className="mx-3" >
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Product Name
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="productname"
                        placeholder="Enter Product Name"
                        value={product.productname}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide Product Name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicTitle">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicTitle"
                      >
                        Product Code
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="productcode"
                        placeholder="Enter Product Code"
                        value={product.productcode}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row lg={12}>
                  <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicTitle">
                      <Form.Label
                        className="form-view-label mx-3"
                        htmlFor="formBasicTitle"
                      >
                        Category
                      </Form.Label>
                      <Form.Select required aria-label="Enter Category" name="category" onChange={handleChange} value={product.category}>
                        <option value="">--Select-Category--</option>
                        {productCategory.map((item, index) => (
                          <option value={item.label} key={index}>
                            {item.label}
                          </option>
                        ))}
  
  
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group controlId="formBasicFirstName" className="mx-3">
                      <Form.Label
                        className="form-view-label mx-3"
                        htmlFor="formBasicFirstName">
                        Subcategory
                      </Form.Label>
                      <Form.Select required aria-label="Enter Category" name="subcategory" onChange={handleChange} value={product.subcategory}>
                        <option value="">--Select-Subcategory--</option>
                        {producSubcategoryArray.map((item, index) => (
                          <option value={item.label} key={index}>
                            {item.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                {/* <Row lg={6}>
                  <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicPin">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicPin"
                      >
                        Instock
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="unitcount"
                        placeholder="Enter Instock"
                        value={product.unitcount}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row> */}
                <Row lg={12}>
                  <Col lg={12}>
                    <Form.Group className="mx-3" controlId="formBasicLastName" >
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicLastName"
                      >
                        Description
                      </Form.Label>
                      <Form.Control as="textarea"
                        name="description"
                        placeholder="Enter Description"
                        value={product.description}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Row>
            </Form>
          </Col>
          <Col></Col>
        </Row>
      </Container >
    );
  };
  export default ProductEdit;
  /* End updated by Pooja Vaishnav */
  