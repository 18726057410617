import React, { useState, useEffect } from "react";
import SparkApi from "../api/SparkApi";
import { Container } from "react-bootstrap";
import { Col, Row, Table, Button, Card } from "react-bootstrap";
import BarChart from "../components/charts/BarChart";
import PieChart from "../components/charts/PieChart";
import VerticalChart from "../components/charts/VerticalChart";
import { ListGroup } from "react-bootstrap";
import { Link, Navigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PubSub from "pubsub-js";
//import { useNavigate } from "react-router-dom";

const DashboardEdit = () => {
  const [body, setBody] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [reportName, setReportName] = useState({});
  //const [chartCollection, setChartCollection] = useState({});
  const [reportData, setReportData] = useState([]);
  const [reportName1, setReportName1] = useState([]);
  const [querydata, setQueryData] = useState([]);
  const [previousdata, setPreviousData] = useState([]);
  const [updateddata, setUpdatedData] = useState([]);
  const [isCodeBlockExecuted, setIsCodeBlockExecuted] = useState(false);

  const initialDashboardData = {
    queryResult: [],
  };
  const [dashboard, setDashboard] = useState([]);

  const [dashboardid, setDashboardId] = useState(location.state ? location.state : {});


  useEffect(() => {
    fetchAllReports();
  }, []);

  const fetchAllReports = async () => {
    try {
      const reports = await SparkApi.fetchAllReports();
      let filterarray = reports.filter((value, index, array) => value.iscustom === true)
      ////('filterarray',filterarray)
      setBody(filterarray || []);
    } catch (error) {
      console.error("Error fetching reports:", error);
      setBody([]);
    }
  };

  function allowDrop(ev) {
    ev.preventDefault();
  }

  function drag(ev, index, item) {
    ev.dataTransfer.setData("text", JSON.stringify({ index, item }));
  }

  let newReportData = [];

  const drop = async (ev, newIndex) => {
    ev.preventDefault();
    const data = ev.dataTransfer.getData("text");
    const { index, item } = JSON.parse(data);
    setPreviousData(reportData);




    const queryResult = await SparkApi.fetchGroupbyQueryData(item.groupbyquery.query);

    let arr = [...querydata];
    let brr = [...reportName1];


    arr[newIndex - 1] = queryResult;

    brr[newIndex - 1] = item.name;
    setQueryData(arr);
    setReportName1(brr);

    const newQueryResultItem = {
      box: newIndex,
      reportId: item.id,
      reportName: item.name,
    };

    if (!isCodeBlockExecuted) {
      setIsCodeBlockExecuted(true);

      if (Array.isArray(reportData?.fetchReports)) {
        newReportData = reportData.fetchReports.map((item) => {
          const newQueryResultItem = {
            box: item.boxid,
            reportId: item.reportdata.id,
            reportName: item.reportdata.name
          };
          return newQueryResultItem;
        });
      }

    }

    setDashboard((prevDashboard) => {
      let queryResultArray = prevDashboard.queryResult || [];

      const updatedQueryResult = [
        ...queryResultArray.filter((prevItem) => prevItem.box !== newIndex),
        newQueryResultItem,
        ...newReportData,
      ];
      return {
        ...prevDashboard,
        queryResult: updatedQueryResult,
      };
    });

  };

  useEffect(() => {
    async function init() {
      fetchDashboard();
    }
    init();
  }, []);

  const fetchDashboard = async () => {
    const reports = await SparkApi.fetchDashboardById(dashboardid);
    if (reports) {
      setReportData(reports || []);
    } else {
      console.error("No reports or invalid report data received.");
    }
  };

  useEffect(() => {
    if (reportData) {
      let arr = [];
      let brr = [];

      reportData?.fetchReports?.forEach((data) => {
        arr[data.boxid - 1] = data.reportdata.name;
      });

      reportData?.reportQueriesData?.forEach((dta) => {
        brr[dta.boxid - 1] = dta.querydata;
      });

      setReportName1([...reportName1, ...arr], [...querydata, ...brr]);
      setQueryData([...querydata, ...brr]);
    }
  }, [reportData]);



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await SparkApi.saveDashboard(dashboardid, dashboard.queryResult);
      if (result) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate("/dashboards");
      } else {
        console.error("Failed to create the dashboard. Result is empty.");
      }
    } catch (error) {
      //console.error("Error creating the dashboard:", error);
    }
  };

  return (
    <>
      <Row className="my-3 mx-1">
        <Link className="nav-link my-2" to="/dashboards">
          Home <i className="fa-solid fa-chevron-right"></i> <div style={{ color: "#23468c", display: "inline" }}>Dashboards</div>
        </Link>
        <Col lg={12}>
          <nav
            className="navbar navbar-light"
            style={{ backgroundColor: "#fff" }}
          >
            <Col lg={7}>
              <h5 style={{ marginLeft: "10px" }} className="my-2 capitalFields">{reportData.name}</h5>
            </Col>
            <Col lg={5} className="d-flex justify-content-end">
              <Button variant="primary" className="mx-3" onClick={handleSubmit}>
                Save
              </Button>
            </Col>
          </nav>
        </Col>
      </Row>
      <Row >
        <Col lg={3}>
          <Card>
            <Card.Body
              style={{
                // boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.6)",
                backgroundColor: "#6495ED",
              }}
            >
              <Card.Title className="text-center">
                <strong style={{ color: "white",fontSize : '12px' }}> AVAILABLE REPORTS</strong>
              </Card.Title>
              <ListGroup>
                {body.map((item, index) => (
                  <ListGroup.Item
                    key={item.name}
                    className="custom-list-item capitalFields report-name"
                    draggable="true"
                    onDragStart={(event) => drag(event, index, item)}
                    style={{
                      // boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.6)",
                      backgroundColor: "#6495ED",
                      color: "#FFFAFA",
                      cursor: "pointer",
                    }}
                  >
                    <span>{item.name}</span>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={9}>
          <Row>
            <Col lg={6}>
              <div
                onDrop={(event) => drop(event, 1)}
                onDragOver={(event) => allowDrop(event)}
              >
                <Card>
                  <Card.Header style={{ height: "40px" }}>
                    {reportName1[0] && (
                      <b>
                        <h5 className="my-2 capitalFields report-name">{reportName1[0]}</h5>
                      </b>
                    )}
                  </Card.Header>
                  <Card.Body>
                    {Array.isArray(querydata[0]) ? (
                      <div style={{ height: "400px" }}>
                        <BarChart data={querydata[0]} />
                      </div>
                    ) : (
                      <div style={{ height: "400px" }}>
                        <h5
                          style={{
                            color: "grey",
                            textAlign: "center",
                            paddingTop: "150px",
                            fontWeight: "bold",
                          }}
                        >
                          Drag 1
                        </h5>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </div>
            </Col>

            <Col lg={6}>
              <div
                onDrop={(event) => drop(event, 2)}
                onDragOver={(event) => allowDrop(event)}
              >
                <Card>
                  <Card.Header style={{ height: "40px" }}>
                    {reportName1[1] && (
                      <b>
                        <h5 className="my-2 capitalFields report-name">{reportName1[1]}</h5>
                      </b>
                    )}
                  </Card.Header>
                  <Card.Body>
                    {Array.isArray(querydata[1]) ? (
                      <div style={{ height: "400px", marginLeft: "-150px" }}>
                        <PieChart data2={querydata[1]} />
                      </div>
                    ) : (
                      <div style={{ height: "400px" }}>
                        <h5
                          style={{
                            color: "grey",
                            textAlign: "center",
                            paddingTop: "150px",
                            fontWeight: "bold",
                          }}
                        >
                          Drag 2
                        </h5>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </div>
            </Col>

            <Col lg={6} className="my-4">
              <div
                onDrop={(event) => drop(event, 3)}
                onDragOver={(event) => allowDrop(event)}
              >
                <Card className="">
                  <Card.Header style={{ height: "40px" }}>
                    {reportName1[2] && (
                      <b>
                        <h5 className="my-2 capitalFields report-name">{reportName1[2]}</h5>
                      </b>
                    )}
                  </Card.Header>
                  <Card.Body>
                    {Array.isArray(querydata[2]) ? (
                      <div style={{ height: "400px" }}>
                        <VerticalChart data1={querydata[2]} />
                      </div>
                    ) : (
                      <div style={{ height: "400px" }}>
                        <h5
                          style={{
                            color: "grey",
                            textAlign: "center",
                            paddingTop: "150px",
                            fontWeight: "bold",
                          }}
                        >
                          Drag 3
                        </h5>
                      </div>
                    )}

                  </Card.Body>
                </Card>
              </div>
            </Col>

            <Col lg={6} className="my-4">
              <div
                onDrop={(event) => drop(event, 4)}
                onDragOver={(event) => allowDrop(event)}
              >
                <Card className="">
                  <Card.Header style={{ height: "40px" }}>
                    {reportName1[3] && (
                      <b>
                        <h5 className="my-2 capitalFields report-name">{reportName1[3]}</h5>
                      </b>
                    )}
                  </Card.Header>
                  <Card.Body>
                    {Array.isArray(querydata[3]) ? (
                      <div style={{ height: "400px" }}>
                        <BarChart data={querydata[3]} />
                      </div>
                    ) : (
                      <div style={{ height: "400px" }}>
                        <h5
                          style={{
                            color: "grey",
                            textAlign: "center",
                            paddingTop: "150px",
                            fontWeight: "bold",
                          }}
                        >
                          Drag 4
                        </h5>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default DashboardEdit;
