import React, { useEffect, useState } from 'react'
import { Badge, Button, Container, Form, Modal, Stack, Table } from 'react-bootstrap';
import csvDownload from 'json-to-csv-export'
// import { DatatableWrapper, TableBody, TableHeader } from 'react-bs-datatable';
// import WizardNav from './WizardNav';
// import SparkApi from '../../api/SparkApi';
// import * as constants from "../../constants/CONSTANT";

export default function CSVRecordsInfo({ showTable, setShowTable, records, setRecords }) {
    // This state for user's 
    // const [users, setUsers] = useState(null);

    //--------All API related functionality------
    // For Fetch the user's
    // async function fetchUsers() {
    //     const fetchUsers = await SparkApi.fetchUsers();
    //     setUsers(fetchUsers);
    // }


    // useEffect(() => {
    //     fetchUsers();
    // }, []);

    // useEffect(() => {
    //     console.log("records", records)
    // }, [records]);

    //-------All handler and component related functionality-------

    // To handle change of table
    // function handleChange(customer_id, event) {
    //     if (records && Array.isArray(records)) {
    //         records.forEach((record) => {
    //             if (record?.customerid == customer_id) {
    //                 if (record?.hasOwnProperty(event?.target?.name)) record[event?.target?.name] = event?.target?.value;
    //             }
    //         });
    //         setRecords([...records]);
    //     }
    // }
    return (
        (
            <Modal
                show={showTable}
                onHide={() => { setShowTable(false) }}
                aria-labelledby="BulkUploadDetailModal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="BulkUploadDetailModal">
                        Bulk Upload details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='d-flex justify-content-center gap-3'>

                    <Button
                        variant='success'
                        className='d-flex align-items-center gap-3'
                        disabled={!records?.insertedRecords.length > 0}
                        onClick={() => {
                            csvDownload({
                                data: (records?.insertedRecords && records?.insertedRecords.length > 0) ? records?.insertedRecords : [],
                                filename: "InsertedRecords",
                                delimiter: ',',
                                headers: (records?.insertedRecords && records?.insertedRecords.length > 0) ? Object.keys(records?.insertedRecords[0]) : [],
                            })
                        }}
                    >
                        Inserted
                        <i className="fa-solid fa-download"></i>
                        <Badge>{records?.insertedRecords && records?.insertedRecords.length}</Badge>
                    </Button>
                    <Button
                        variant='danger'
                        className='d-flex align-items-center gap-3'
                        disabled={!records?.failedRecords.length > 0}
                        onClick={() => {
                            csvDownload({
                                data: (records?.failedRecords && records?.failedRecords.length > 0) ? records?.failedRecords : [],
                                filename: "FailedRecords",
                                delimiter: ',',
                                headers: (records?.failedRecords && records?.failedRecords.length > 0) ? Object.keys(records?.failedRecords[0]) : [],
                            })
                        }} >
                        Failed
                        <i className="fa-solid fa-download"></i>
                        <Badge>{records?.failedRecords && records?.failedRecords.length}</Badge>
                    </Button>
                </Modal.Body>
            </Modal >
        )
    )
}
