import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";


const Path = (props) => {
    //('props', props)
    const [values, setValues] = useState(props.values ? props.values : []);
    //////('values', values)
    const [selectedValue, setSelectedValue] = useState(props.selectedValue ? props.selectedValue : '');
    const getClassName = (currentVal, selectedVal) => {
        const selectedOption = values.find(val => val.label === selectedVal);
        
        if (!selectedOption) {
            // Handle the case where the selected option is not found
            return "bar-step";
        }
        
        const selectedSortOrder = selectedOption.sortOrder;
        //(currentVal.label, selectedVal);
        
        if (currentVal.label === selectedVal) {
            return "bar-step active";
        } else if (currentVal.sortOrder < selectedSortOrder) {
            return "bar-step completed";
        } else if (selectedVal === 'Risk Inspection/Query') {
            return "bar-step risk";
        } else {
            return "bar-step";
        }
    }
            return (


        <>
            <div className="bar">

                {values.map((val,index) => {
                    return <div key={index} className={
                        getClassName(val, selectedValue)
                    }>{val.label}</div>
                })}

            </div>
        </>
    )
}
export default Path;
