import React, { useState, useEffect } from "react";
import { Badge, Button, Card, Col, Container, Row } from "react-bootstrap";
import Confirm from "./Confirm";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FilesCreate from "./FilesCreate";
import RelatedListFiles from "./RelatedListFiles";
import RelatedListTask from "./RelatedListTask";
import TaskEdit from "./TaskEdit";
import Path from "./common/Path";
import * as constants from '../constants/CONSTANT';
import PubSub from "pubsub-js";
import RelatedListBusiness from "./RelatedListBusiness";
import moment from "moment";
import PaymentRelatedList from "./PaymentRelatedList";
import PaymentEdit from "./PaymentEdit";
import CurrencyFormatter from 'currency-formatter-react'
import helper from "./common/helper";


const BusinessView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  //const course = location.state;
  const [business, setBusiness] = useState(location.state ? location.state : {});
  //////('business', business)
  const [relatedListFiles, setRelatedListFiles] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowFile, setModalShowFile] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [reletedListTask, setReletedListTask] = useState(false);
  const [refreshFileList, setRefreshFileList] = useState();
  const [refreshTaskList, setRefreshTaskList] = useState();
  const [refreshPaymentList, setRefreshPaymentList] = useState();
  

  const [paymentsTab, setpaymentsTab] = useState(false);
  const [modalShowPayment, setModalShowPayment] = useState(false);
  const [relatedListPayment, setRelatedListPayment] = useState(true);
  const [receivedPayment, setReceivedPayment] = useState(0);
  
  let userInfo;
  useEffect(() => {
    
   
    ////(helper.checkPermission(constants.MODIFY_ALL));
    fetchBusiness();
    // async function init() {
    //   const fetchUser = await SparkApi.fetchUsers();
    //   //////("fetchUser=>" + JSON.stringify(fetchUser));
    //   //////("assignrole" + fetchUser.assignrole);
    //   //////("assignrole" + fetchUser.ownerid);
    //   let usr = [];
    //   fetchUser.map((item) => {
    //     if (location.state.assignrole === item.id) {
    //       setUserName(item.username);
    //     }
    //   });
    // }

    // init();
  }, []);

  const fetchBusiness = () => {
    if (
      !business.id &&
      location.hasOwnProperty("pathname") &&
      location.pathname.split("/").length >= 3
    ) {
      business.id = location.pathname.split("/")[2];
    }

    async function initStudent() {
      let result = await SparkApi.fetchBusinessById(business.id);
      ////('result business by id', result);

      if (result) {
        //////("=======", result);

        setBusiness(result);
        //////("name : ", business.firstname);
      } else {
        setBusiness({});
      }
    }
    initStudent();
  };
  const deleteRecord = async () => {
    const result = await SparkApi.deleteBusiness(business.id);
    ////(result)
    if (result.success) navigate(`/business`);
    //////("result.success : ", result.success);
  };
  const editBusiness = () => {
    navigate(`/business/${business.id}/e`, { state: business });
  };

  const handleSelect = (key) => {
    if (key === "tasks") {
      setReletedListTask(true);
      setRelatedListFiles(false);
      setRelatedListPayment(false);
    } else if (key === "files") {
      setRelatedListFiles(true);
      setReletedListTask(false);
      setRelatedListPayment(false);
    }
    else if (key === "payment") {
      setRelatedListFiles(false);
      setReletedListTask(false);
      setRelatedListPayment(true);
    }
  };

  const submitFiles = () => {
    setModalShowFile(false);
    setRefreshFileList(Date.now());
    setModalShowTask(false);
  };



  const submitpayments = () => {
    setModalShowPayment(false);
    setRefreshPaymentList(Date.now());
    setModalShowTask(false);
  };



  const submitTasks = () => {
    setModalShowTask(false);
    setRefreshTaskList(Date.now());
  };

  const onPaymentListUpdate = (total) => {
    setReceivedPayment(total);
  }

  return (
    <div>
      {business && (
        <Container>
          {modalShow && (
            <Confirm
              show={modalShow}
              onHide={() => setModalShow(false)}
              deleteRecord={deleteRecord}
              title="Confirm delete?"
              message="You are going to delete the record. Are you sure?"
              table="general"
            />
          )}
          <Row className="view-form">
            <Col lg={11} className="pb-3 pt-2">
              <Link className="nav-link" to="/business">
                Home <i className="fa-solid fa-chevron-right"></i>{" "}
                <div style={{ color: "#23468c", display: "inline" }}>
                  Business
                </div>
              </Link>
            </Col>
            <Col></Col>
          </Row>
          <Row className="view-form">
            <Col></Col>
            <Col lg={11}>
              <Row className="view-form-header align-items-center">
                <Col lg={8}>
                  Business
                  <h5>{business.name}</h5>
                </Col>
                <Col lg={4} className="d-flex justify-content-end">
                  <Button
                    className="btn-sm mx-2"
                    onClick={() => editBusiness(true)}
                  >
                    <i className="fa-regular fa-pen-to-square"></i>
                  </Button>
                 {helper.checkPermission(constants.MODIFY_ALL) && <Button
                    className="btn-sm"
                    variant="danger"
                    onClick={() => setModalShow(true)}
                  >
                    Delete
                  </Button>}
                </Col>
              </Row>

              <Row className="py-3 ibs-edit-form">
                <Col lg={6}>
                  <label>Business Name</label>
                  <span>{business.name}</span>
                </Col>

                <Col lg={6}>
                  <label>Contact</label>
                  <span>
                    {" "}
                    <Link to={"/contacts/" + business.contactid}>
                      {business.contactname}
                    </Link>
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Account</label>
                  <span>
                    {" "}
                    <Link to={"/accounts/" + business.accountid}>
                      {business.accountname}
                    </Link>
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Lead Source</label>
                  <span>{business.leadsource}</span>
                </Col>

                <Col lg={6}>
                  <label>Industry</label>
                  <span>{business.industry}</span>
                </Col>

                <Col lg={6}>
                  <label>Owner</label>
                  <span>
                    <Badge
                      bg="warning"
                      style={{ display: "inline", color: "#000" }}
                    >
                      {business.ownername}
                    </Badge>
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Created Date</label>
                  <span>
                    {moment(business.createddate).format("DD MMM, yyyy")}
                  </span>
                </Col>
                <Col lg={12}>
                  <label>Description</label>
                  <span>{business.description}</span>
                </Col>
                <Col lg={12} className="section-header">
                  AMOUNT INFORMATION
                </Col>
                <Col lg={6}>
                  <label>Payment Model</label>
                  <span>{business.paymentmodel}</span>
                </Col>
                <Col lg={6}>
                  <label>Payment Terms</label>
                  <span>{business.paymentterms}</span>
                </Col>
                <Col lg={6}>
                  <label>Total Amount</label>
                  <Badge
                    bg="secondary"
                    style={{
                      width: "30%",
                      fontWeight: "normal",
                      fontSize: "1rem",
                      textAlign: "left",
                    }}
                  >
                    <CurrencyFormatter
                      value={business.amount}
                      currency="INR"
                      thousandSeparator={false}
                    />{" "} 
                  </Badge>
                </Col>
                <Col lg={6}>
                  <label>Received Amount</label>
                  <Badge
                    bg="success"
                    style={{
                      width: "30%",
                      fontWeight: "normal",
                      fontSize: "1rem",
                      textAlign: "left",
                    }}
                  >
                    <CurrencyFormatter
                      value={receivedPayment}
                      currency="INR"
                      thousandSeparator={false}
                    />{" "}
                  </Badge>
                </Col>
                <Col lg={6}>
                  <label>Pending Amount</label>

                  <Badge
                    bg="danger"
                    style={{
                      width: "30%",
                      fontWeight: "normal",
                      fontSize: "1rem",
                      textAlign: "left",
                    }}
                  >
                    <CurrencyFormatter
                      value={business.amount - receivedPayment}
                      currency="INR"
                      thousandSeparator={false}
                    />{" "} 
                  </Badge>
                </Col>

                <Col></Col>
              </Row>

              <Card bg="light" text="light" className="mb-2 mt-4">
                <Card.Header className="d-flex justify-content-between">
                  <Tabs
                    defaultActiveKey="payment"
                    id="uncontrolled-tab-example"
                    onSelect={(key) => handleSelect(key)}
                  >
                    {/* <Tab eventKey="patienttests" title="Medical Tests"></Tab> */}
                    <Tab eventKey="files" title="Files"></Tab>
                    <Tab eventKey="tasks" title="Tasks"></Tab>
                    <Tab eventKey="payment" title="Payment"></Tab>
                  </Tabs>

                  {relatedListFiles && (
                    <Button
                      className="float-right btn-sm"
                      onClick={() => setModalShowFile(true)}
                    >
                      New File
                    </Button>
                  )}

                  {relatedListPayment && (
                    <Button
                      className="float-right btn-sm"
                      onClick={() => setModalShowPayment(true)}
                    >
                      New Payment
                    </Button>
                  )}

                  {modalShowFile && (
                    <FilesCreate
                      show={modalShowFile}
                      onHide={() => setModalShowFile(false)}
                      parent={business}
                      table="medicaltest"
                      submitFiles={submitFiles}
                    />
                  )}

                  {modalShowPayment && (
                    <PaymentEdit
                      show={modalShowPayment}
                      onHide={() => setModalShowPayment(false)}
                      parent={business}
                      table="paymentTablel"
                      submitpayments={submitpayments}
                    />
                  )}

                  {reletedListTask && (
                    <Button
                      className="float-right btn-sm"
                      onClick={() => setModalShowTask(true)}
                    >
                      New Task
                    </Button>
                  )}

                  {modalShowTask && (
                    <TaskEdit
                      show={modalShowTask}
                      onHide={() => setModalShowTask(false)}
                      parentid={business?.id}
                      table="business"
                      submitTasks={submitTasks}
                    />
                  )}
                </Card.Header>
                <Card.Body>
                  {relatedListFiles && (
                    <RelatedListFiles
                      refreshFileList={refreshFileList}
                      parent={business}
                    />
                  )}
                  {reletedListTask && (
                    <RelatedListTask
                      refreshTaskList={refreshTaskList}
                      parent={business}
                    />
                  )}
                  {relatedListPayment && (
                    <PaymentRelatedList
                      refreshPaymentList={refreshPaymentList}
                      parent={business}
                      onPaymentListUpdate={onPaymentListUpdate}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      )}
    </div>
  );
};
export default BusinessView;
