import React, { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Row,
  Table,
} from "react-bootstrap";
import Confirm from "./Confirm";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FilesCreate from "./FilesCreate";
import RelatedListFiles from "./RelatedListFiles";
import RealetedLeadTest from "./RealetedLeadTest";
import RelatedListTask from "./RelatedListTask";
import TaskEdit from "./TaskEdit";
import Path from "./common/Path";
import moment from "moment";
import * as constants from "../constants/CONSTANT";
import PubSub from "pubsub-js";
import EmailComposer from "./common/EmailComposer";
import Chat from "./common/Chat";
import jwt_decode from "jwt-decode";
import UnderWriterPath from "./common/UnderWriterPath";

const LeadView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  //const course = location.state;
  const [lead, setLead] = useState(location.state ? location.state : {});
  //('lead===>', lead)
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [relatedListFiles, setRelatedListFiles] = useState(true);
  const [relatedListLeadtTests, setRelatedListLeadtTests] = useState(true);
  const [emailinfo, setemailinfo] = useState({});
  const [modalShowFile, setModalShowFile] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [modalShowEmail, setModalShowEmail] = useState(false);
  const [reletedListTask, setReletedListTask] = useState(false);
  const [refreshFileList, setRefreshFileList] = useState();
  const [userName, setUserName] = useState("");
  const [refreshTaskList, setRefreshTaskList] = useState();
  const [convertShow, setConvertShow] = useState(true);
  // const [leadStatusArray, setleadStatusArray] = useState(
  //   JSON.parse(localStorage.getItem("lead_status"))
  // );

  const statusOptions = [
    { label: "Quote Preparation in Progress", sortOrder: 1 },
    { label: "Quote Sent to Insurance Company", sortOrder: 2 },
    { label: "Data Insufficient", sortOrder: 3 },
    { label: "Quote/Reply Received from Insurance Company", sortOrder: 4 },
  ];

  const statusOptionsrisk = [
    { label: "Quote Preparation in Progress", sortOrder: 1 },
    { label: "Quote Sent to Insurance Company", sortOrder: 2 },
    { label: "Quote/Reply Received from Insurance Company", sortOrder: 3 },
    { label: "Risk Inspection/Query", sortOrder: 4 },
    { label: "Quote Sent to RM", sortOrder: 5 },
    { label: "Data Insufficient", sortOrder: 6 },
  ];

  const leadStatusArray = [
    { label: "Open - Not Contacted", sortOrder: 1 },
    { label: "Work in Progress", sortOrder: 2 },
    { label: "Details Awaited", sortOrder: 3 },
    { label: "Proposal on Hold", sortOrder: 4 },
    { label: "RFQ in Progress", sortOrder: 5 },
    { label: "RFQ Sent to UW", sortOrder: 6 },
    { label: "Query Raised by Underwriter", sortOrder: 7 },
    { label: "RFQ Reassigned to Underwriter", sortOrder: 8 },
    { label: "Quote Received", sortOrder: 9 },
    { label: "Quote Submitted to Client", sortOrder: 10 },
    { label: "Revert Pending from Client", sortOrder: 11 },
    { label: "Closed - Converted", sortOrder: 12 },
    { label: "Closed - Not Converted", sortOrder: 13 },
    { label: "Closed - Partial Converted", sortOrder: 14 },

    // New Change : added new status 21 OCT 2024
    { label: "Quote Reassigned to UW", sortOrder: 15 },
    { label: "Quote Re-Submitted to Client", sortOrder: 16 },
  ];

  //('leadStatusArray', leadStatusArray)

  useEffect(() => {
    fetchAlllead();
    fetchLead();
    fetchAllUser();

    //init();
  }, []);
  const [leadlist, setleadlist] = useState([]);
  const [userlist, setUserList] = useState([]);

  const fetchAlllead = async () => {
    const result = await SparkApi.fetchLead();
    setleadlist(result);
  };

  const fetchAllUser = async () => {
    const result = await SparkApi.fetchUsers();
    setUserList(result);
  };

  const fetchLead = () => {
    if (
      !lead.id &&
      location.hasOwnProperty("pathname") &&
      location.pathname.split("/").length >= 3
    ) {
      lead.id = location.pathname.split("/")[2];
    }

    async function initStudent() {
      let result = await SparkApi.fetchLeadById(lead.id);
      emailinfo.to = result.owneremail;
      if (
        result?.convertedcontactid &&
        result?.contactname &&
        result.contactname.trim().length > 0
      ) {
        //////('convert call');
        setConvertShow(false);
      }
      if (result) {
        //////("=======", result);

        setLead(result);
        setData(result);
        //////("name : ", lead.firstname);
      } else {
        setLead({});
      }
    }
    initStudent();
  };
  const deleteLead = async () => {
    const result = await SparkApi.deleteLead(lead.id);
    if (result.success) navigate(`/leads`);
    //////("result.success : ", result.success);
  };
  const editLead = () => {
    navigate(`/leads/${lead.id}/e`, { state: lead });
  };

  const handleSelect = (key) => {
    if (key === "tasks") {
      setReletedListTask(true);
      setRelatedListFiles(false);
    } else if (key === "files") {
      setRelatedListFiles(true);
      setReletedListTask(false);
    }
  };

  const submitFiles = () => {
    setModalShowFile(false);
    setRefreshFileList(Date.now());
    setModalShowTask(false);
  };

  const handleConvert = async () => {
    let convertStatus = "Closed - Converted";
    let status = leadStatusArray.filter((item) => item.is_converted === true);
    // console.log("status:", status);
    let filterLeadEmail = leadlist.filter(
      (value, index, array) => value.id === lead.id
    );
    // console.log("filterLeadEmail", filterLeadEmail);

    const result = await SparkApi.convertLeadToContact(lead.id, convertStatus);
    let userInfo = jwt_decode(localStorage.getItem("token"));
    let filterLoginUserEmail = userlist.filter(
      (value, index, array) => value.id === userInfo.id
    );
    emailinfo.editorHtml = `<p><strong>Your Lead is converted name as  ${filterLeadEmail[0].firstname} ${filterLeadEmail[0].lastname}</strong></p>`;

    emailinfo.cc = filterLoginUserEmail[0].email;
    emailinfo.subject = "Lead Converted";
    emailinfo.from = `${userInfo.username}<${userInfo.email}>`;
    emailinfo.ownerid = userInfo.id;
    emailinfo.parentid = lead.id;
    const emailsend = await SparkApi.sendEmailTask(emailinfo);

    if (result) {
      let contactId = result.convertedcontactid;
      PubSub.publish("RECORD_SAVED_TOAST", {
        title: "Lead Convert ",
        message: "Record Convert successfully",
      });
      navigate(`/contacts/${contactId}`);
      //////("result.success : ", result.success);
    }
  };

  const submitTasks = () => {
    setModalShowTask(false);
    setModalShowEmail(false);
    setRefreshTaskList(Date.now());
  };

  return (
    <Container fluid>
      {lead && (
        <>
          {modalShow && (
            <Confirm
              show={modalShow}
              onHide={() => setModalShow(false)}
              deleteLead={deleteLead}
              title="Confirm delete?"
              message="You are going to delete the record. Are you sure?"
              table="lead"
            />
          )}
          <Row className="view-form">
            <Col lg={11} className="pb-3 pt-2 mx-3">
              <Link className="nav-link" to="/leads">
                Home <i className="fa-solid fa-chevron-right"></i>{" "}
                <div style={{ color: "#23468c", display: "inline" }}>Leads</div>
              </Link>
            </Col>
            <Col></Col>
          </Row>

          <Row className="view-form gx-5 mx-auto">
            <Col lg={9}>
              <Row className="view-form-header align-items-center">
                <Col lg={8}>
                  Lead
                  <h5>
                    {lead.salutation +
                      " " +
                      lead.firstname +
                      " " +
                      lead.lastname}
                  </h5>
                </Col>
                <Col lg={4} className="d-flex justify-content-end">
                  <Button
                    className="btn-sm mx-2"
                    onClick={() => editLead(true)}
                  >
                    <i className="fa-regular fa-pen-to-square"></i>
                  </Button>
                  <Button
                    className="btn-sm mx-2"
                    variant="danger"
                    onClick={() => setModalShow(true)}
                  >
                    Delete
                  </Button>
                  <Button
                    className="float-right btn-sm"
                    onClick={() => setModalShowEmail(true)}
                    variant="success"
                    title="Send Email"
                  >
                    <i className="fa-regular fa-envelope mx-2"></i>
                  </Button>
                  {convertShow === true ? (
                    <Button
                      className="btn-sm mx-2"
                      variant="warning"
                      onClick={handleConvert}
                    >
                      Convert
                    </Button>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <Row className="py-3 ibs-edit-form">
                <Col lg={12} className="overflow-auto">
                  {lead.leadstatus && (
                    <Path
                      values={leadStatusArray}
                      selectedValue={lead.leadstatus}
                    />
                  )}{" "}
                  <br />
                </Col>


                <Col lg={12} className="section-header">
                  Relationship Manager Details
                </Col>
                <Col lg={6}>
                  <label>Relationship Manager</label>
                  <span>
                    <Badge
                      bg="warning "
                      style={{ display: "inline", color: "#000" }}
                    >
                      {lead.rfqname}
                    </Badge>
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Admin Manager</label>
                  <span>
                    <Badge
                      bg="warning"
                      style={{ display: "inline", color: "#000" }}
                    >
                      {lead.ownername}
                    </Badge>
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Lead Status</label>
                  <span>
                    <Badge>{lead.leadstatus}</Badge>
                  </span>
                </Col>

                <Col lg={6}>
                  <label>Type of Business</label>
                  <span>{lead.typeofbusiness}</span>
                </Col>
                <Col lg={6}>
                  <label>Source</label>
                  <span>{lead.leadsource}</span>
                </Col>
                <Col lg={6}>
                  <label>REFERRAL LOCATION </label>
                  <span>{lead.industry}</span>
                </Col>

                {/* Added new field Referral Name 24 Sept 2024 @ali */}
                <Col lg={6}>
                  <label>REFERRAL NAME </label>
                  <span>{lead.referral_name}</span>
                </Col>

                <Col lg={6}>
                  <label>Lead Contact Date</label>
                  <span>{moment(lead.firstmeetingdate).format("DD-MM-YYYY")} </span>
                </Col>
                <Col lg={12} className="section-header">
                  LEADS DETAILS
                </Col>
                <Col lg={6}>
                  <label>Type of Customer</label>
                  <span>{lead.typeofcustomer} </span>
                </Col>
                <Col lg={6}>
                  <label>Company</label>
                  <span>{lead.company}</span>
                </Col>
                <Col lg={6}>
                  <label>Comapny Group</label>
                  <span>{lead.comapnygroup} </span>
                </Col>
                <Col lg={6}>
                  <label>SALUTATION</label>
                  <span>{lead.salutation}</span>
                </Col>
                <Col lg={6}>
                  <label>Lead Name</label>
                  <span>{lead.firstname}</span>
                </Col>
                <Col lg={6}>
                  <label>Last Name</label>
                  <span>{lead.lastname}</span>
                </Col>
                <Col lg={6}>
                  <label>Title</label>
                  <span>{lead.title}</span>
                </Col>
                <Col lg={6}>
                  <label>Mobile</label>
                  <span>{lead.phone}</span>
                </Col>
                <Col lg={6}>
                  <label>Email</label>
                  <span>{lead.email}</span>
                </Col>

                <Col lg={6}>
                  <label> Street</label>
                  <span>{lead.street}</span>
                </Col>
                <Col lg={6}>
                  <label>City</label>
                  <span>
                    {lead.city !== null ? lead.city : ""}
                  </span>
                </Col>
                <Col lg={6}>
                  <label>State</label>
                  <span>{lead.state}</span>
                </Col>

                <Col lg={6}>
                  <label>Pincode</label>
                  <span>{lead.zipcode}</span>
                </Col>

                <Col lg={6}>
                  <label>First Quotation Release Date</label>
                  <span>
                    {lead.quatationrecdate
                      ? moment(lead.quatationrecdate).format("DD MMM, yyyy")
                      : ""}
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Renewal Date</label>
                  <span>
                    {lead.renewal_date
                      ? moment(lead.renewal_date).format("DD MMM, yyyy")
                      : ""}
                  </span>
                </Col>
                <Col lg={6}>
                  <label>RFQ Date</label>
                  <span>
                    {lead.rfq_date
                      ? moment(lead.rfq_date).format("DD MMM, yyyy")
                      : ""}
                  </span>
                </Col>
                {lead.iswon === true && (
                  <Col lg={6}>
                    <label>Converted Contact</label>
                    <span>
                      <Link to={"/contacts/" + lead.convertedcontactid}>
                        {lead.contactname}
                      </Link>
                    </span>
                  </Col>
                )}

                <Col lg={6}>
                  <label>Created Date</label>
                  <span>{moment(lead.createddate).format("DD MMM, yyyy")}</span>
                </Col>
                {/* New Field 26-Oct- */}
                <Col lg={6}>
                  <label>Lastmodified Date</label>
                  <span>{moment(lead.lastmodifieddate).format("DD MMM, yyyy")}</span>
                </Col>
                <Col lg={12} className="section-header">
                  LEAD CONVERSION DETAILS
                </Col>

                <Col lg={6}>
                  <label>Insurance Company</label>
                  <span>{lead.insurancetype}</span>
                </Col>
                <Col lg={6}>
                  <label>Final Premium</label>
                  <span>₹ {lead.amount}</span>
                </Col>
                <Col lg={6}>
                  <label>Lead Remark</label>
                  <span>{lead.leadremark}</span>
                </Col>
                <Col lg={6}>
                  <label>Reason for Case Lost</label>
                  <span>{lead.lostreason}</span>
                </Col>

              </Row>
            </Col>
            <Col lg={3}>
              <Chat parentid={lead.id} />
            </Col>
          </Row>

          <Card bg="light" text="light" className="mb-2 mt-4">
            <Card.Header className="d-flex justify-content-between">
              <Tabs
                defaultActiveKey="files"
                id="uncontrolled-tab-example"
                onSelect={(key) => handleSelect(key)}
              >
                {/* <Tab eventKey="patienttests" title="Medical Tests"></Tab> */}
                <Tab eventKey="files" title="Files"></Tab>
                <Tab eventKey="tasks" title="Tasks"></Tab>
              </Tabs>

              {relatedListFiles && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowFile(true)}
                >
                  New File
                </Button>
              )}
              {modalShowFile && (
                <FilesCreate
                  show={modalShowFile}
                  onHide={() => setModalShowFile(false)}
                  parent={lead}
                  table="medicaltest"
                  submitFiles={submitFiles}
                />
              )}

              {reletedListTask && (
                <div>
                  <Button
                    className="float-right btn-sm mx-2"
                    onClick={() => setModalShowTask(true)}
                  >
                    New Task
                  </Button>

                  <Button
                    className="float-right btn-sm"
                    onClick={() => setModalShowEmail(true)}
                    variant="success"
                  >
                    <i className="fa-regular fa-envelope mx-2"></i>
                    Send Email
                  </Button>
                </div>
              )}
              {modalShowTask && (
                <TaskEdit
                  show={modalShowTask}
                  onHide={() => setModalShowTask(false)}
                  parentid={lead?.id}
                  table="lead"
                  lead={lead}
                  submitTasks={submitTasks}
                />
              )}
            </Card.Header>
            <Card.Body>
              {relatedListFiles && (
                <RelatedListFiles
                  refreshFileList={refreshFileList}
                  parent={lead}
                />
              )}
              {reletedListTask ? (
                <RelatedListTask
                  refreshTaskList={refreshTaskList}
                  parent={lead}
                />
              ) : (
                ""
              )}
            </Card.Body>
          </Card>

          <EmailComposer
            size="lg"
            show={modalShowEmail}
            onHide={() => setModalShowEmail(false)}
            parentid={lead?.id}
            toEmail={lead?.email}
            table="lead"
            submitTasks={submitTasks}
          />
        </>
      )}
    </Container>
  );
};
export default LeadView;
