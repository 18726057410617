import React, { useState, useEffect } from "react";
import SparkApi from "../api/SparkApi";
import { Container } from "react-bootstrap";
import { Col, Row, Table, Button, Card } from "react-bootstrap";
import BarChart from "../components/charts/BarChart";
import PieChart from "../components/charts/PieChart";
import VerticalChart from "../components/charts/VerticalChart";
import { ListGroup } from "react-bootstrap";
import { Link, Navigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PubSub from "pubsub-js";


const Dashboard = () => {
  const [body, setBody] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [reportName, setReportName] = useState({});


  const initialDashboardData = {
    queryResult: [],
  };
  const [dashboard, setDashboard] = useState(
    location.state ? location.state : initialDashboardData
  );

  useEffect(() => {
    fetchAllReports();
  }, []);

  const fetchAllReports = async () => {
    try {
      const reports = await SparkApi.fetchAllReports();
      let filterarray = reports.filter((value, index, array) => value.iscustom === true)
      ////('filterarray', filterarray)
      setBody(filterarray || []);
    } catch (error) {
      console.error("Error fetching reports:", error);
      setBody([]);
    }
  };


  function allowDrop(ev) {
    ev.preventDefault();
  }

  function drag(ev, index, item) {
    ev.dataTransfer.setData("text", JSON.stringify({ index, item }));
  }

  const drop = async (ev, newIndex) => {
    ev.preventDefault();
    const data = ev.dataTransfer.getData("text");
    const { index, item } = JSON.parse(data);

    ////("draggedItem +++ ===>", item);


    const queryResult = await SparkApi.fetchGroupbyQueryData(item?.groupbyquery?.query);

    setReportName({ ...reportName, ["report" + newIndex]: item.name, ["chart" + newIndex]: queryResult });


    const newQueryResultItem = {
      box: newIndex,
      reportId: item.id,
      reportName: item.name,
    };

    setDashboard((prevDashboard) => {
      const queryResultArray = prevDashboard.queryResult || [];

      const updatedQueryResult = [
        ...queryResultArray.filter((prevItem) => prevItem.box !== newIndex),
        newQueryResultItem,
      ];
      return {
        ...prevDashboard,
        queryResult: updatedQueryResult,
      };
    });
  };
  ////("reportName --->++", reportName);
  ////("dashboard +++ <<<", dashboard);

  const handleSubmit = async (e) => {


    e.preventDefault();
    try {
      const result = await SparkApi.createDashboard(dashboard);
      if (result) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate("/dashboards");
      } else {
        console.error("Failed to create the dashboard. Result is empty.");
      }
    } catch (error) {
      console.error("Error creating the dashboard:", error);
    }
  };

  const handleCancel = () => {
    navigate(`/dashboards`)
  }

  return (
    <>
      <Row className="my-3 mx-1">
        <Link className="nav-link my-2" to="/dashboards">
          Home <i className="fa-solid fa-chevron-right"></i> <div style={{ color: "#23468c", display: "inline" }}>Dashboards</div>
        </Link>
        <Col lg={12}>
          <nav
            className="navbar navbar-light"
            style={{ backgroundColor: "#fff" }}
          >
            <Col lg={7}>
              <h5 style={{ marginLeft: "10px" }}>New Dashboard</h5>
            </Col>
            <Col lg={5} className="d-flex justify-content-end">
              <Button variant="primary" onClick={handleSubmit}>
                Save
              </Button>
              <Button variant="danger" className="mx-2" onClick={handleCancel}>
                Cancel
              </Button>
            </Col>
          </nav>
        </Col>
      </Row>
      <Row className="mx-1">
        <Col lg={3}>
          <Card
            style={{

              backgroundColor: "#6495ED",
            }}
          >
            <Card.Body>
              <Card.Title className="text-center">
                <strong style={{ color: "white" }}> AVAILABLE REPORTS</strong>
              </Card.Title>
              <ListGroup>
                {body.map((item, index) => (
                  <ListGroup.Item
                    key={item.name}
                    className="custom-list-item capitalFields report-name"
                    draggable="true"
                    onDragStart={(event) => drag(event, index, item)}
                    style={{

                      backgroundColor: "#6495ED",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <span>{item.name}</span>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={9}>
          <Row>
            <Col lg={6}>
              <div
                onDrop={(event) => drop(event, 1)}
                onDragOver={(event) => allowDrop(event)}
              >
                <Card>
                  <Card.Header style={{ height: "40px" }}>
                    {reportName.report1 && (
                      <b>
                        {reportName.report1}

                      </b>
                    )}
                  </Card.Header>
                  <Card.Body >
                    {reportName.report1 ? (
                      <div style={{ height: "400px" }}>
                        <BarChart data={reportName.chart1} />
                      </div>
                    ) : (
                      <div style={{ height: "400px" }}>
                        <h3 style={{ color: "grey", textAlign: "center", paddingTop: "150px", fontWeight: "bold" }}>Drag 1</h3>
                      </div>
                    )}

                  </Card.Body>
                </Card>
              </div>
            </Col>

            <Col lg={6}>
              <div
                onDrop={(event) => drop(event, 2)}
                onDragOver={(event) => allowDrop(event)}
              >
                <Card>
                  <Card.Header style={{ height: "40px" }}>
                    {reportName.report2 && (
                      <b>
                        {reportName.report2}
                        {/* <h4>{dashboard.queryResult[1].reportName}</h4> */}
                      </b>
                    )}
                  </Card.Header>
                  <Card.Body >
                    {reportName.report2 ? (
                      <div style={{ height: "400px", marginLeft: "-150px" }}>

                        <PieChart data2={reportName.chart2} />


                      </div>
                    ) : (
                      <div style={{ height: "400px" }}>
                        <h3 style={{
                          color: "grey", textAlign: "center",
                          paddingTop: "150px", fontWeight: "bold"
                        }}> Drag 2
                        </h3>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </div>
            </Col>

            <Col lg={6} className="my-4">
              <div
                onDrop={(event) => drop(event, 3)}
                onDragOver={(event) => allowDrop(event)}
              >
                <Card className="">
                  <Card.Header style={{ height: "40px" }}>
                    {reportName.report3 && (
                      <b>
                        {reportName.report3}
                        {/* <h4>{dashboard.queryResult[2].reportName}</h4> */}
                      </b>
                    )}
                  </Card.Header>
                  <Card.Body>
                    {reportName.report3 ? (
                      <div style={{ height: "400px" }}>

                        <VerticalChart data1={reportName.chart3} />

                      </div>
                    ) : (
                      <div style={{ height: "400px" }}>
                        <h3 style={{
                          color: "grey", textAlign: "center",
                          paddingTop: "150px", fontWeight: "bold"
                        }}> Drag 3
                        </h3>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </div>
            </Col>

            <Col lg={6} className="my-4">
              <div
                onDrop={(event) => drop(event, 4)}
                onDragOver={(event) => allowDrop(event)}
              >
                <Card className="">
                  <Card.Header style={{ height: "40px" }}>
                    {reportName.report4 && (
                      <b>
                        {reportName.report4}
                      </b>
                    )}
                  </Card.Header>
                  <Card.Body>
                    {reportName.report4 ? (
                      <div style={{ height: "400px" }}>

                        <BarChart data={reportName.chart4} />

                      </div>
                    ) : (
                      <div style={{ height: "400px" }}>
                        <h3 style={{
                          color: "grey", textAlign: "center",
                          paddingTop: "150px", fontWeight: "bold"
                        }}> Drag 4
                        </h3>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
