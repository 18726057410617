import React, { useState } from 'react'
import { Badge, Button, Form, Modal, Spinner, Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';
import SparkApi from '../../api/SparkApi';
import CSVRecordsInfo from './CSVRecordsInfo';
import underwriterCSV from "../../Assets/ExcelFiles/Underwriter_CSV.csv"
import leadCSV from "../../Assets/ExcelFiles/Lead_CSV.csv"

export default function CSVUpload({ userInfo, fetchLeads }) {

    //This state for spinner or loader 30 Sept 2024 @ali
    // const [spinner, setSpinner] = useState(false);
    const [isLoader, setIsLoader] = useState(false);

    //This sates for CSV file upload functionality 25 Sept 2024 @ali
    const [isCSVFiles, setIsCSVFiles] = useState(false);
    const handleClose = () => { setIsCSVFiles(false); setSelectedFile(null) };
    const [selectedFile, setSelectedFile] = useState(null);

    //This state for Show table
    const [showTable, setShowTable] = useState(false);
    const [records, setRecords] = useState(null);


    // Handler for file change
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    //Handler for CSV upload and sent data to Server
    const handleCSVUploadFile = async () => {
        // If file is selected
        if (selectedFile) {
            // Start the loader spinner
            setIsLoader(true);
            //use FormData
            const formData = new FormData();

            // Append the selected file in form data
            formData.append("file", selectedFile);

            // Here we call the Upload CSV API
            const response = await SparkApi.uploadCSVFile(formData);

            // IF file successfully uploaded or no error was found
            if (response && !response?.errors) {
                //Stop the loader spinner
                setIsLoader(false);

                // Close the modal
                setIsCSVFiles(false);

                // Show message in tost container
                toast.success('CSV file successfully Uploaded');

                // Show records table
                setShowTable(true);
                // set table data
                setRecords(response);

                //make empty the file state
                setSelectedFile(null);

                // Re-fetch all leads
                fetchLeads();
            }
            // Other wise
            else {
                //Stop the loader spinner
                setIsLoader(false);
                // Close the modal
                setIsCSVFiles(false);

                // Show error message in tost container
                toast.error(response?.errors ? response?.errors : 'Some error occurred');

                //make empty the file state
                setSelectedFile(null)
            }
        } else {
            console.log('no files');
        }
    };
    return (
        <React.Fragment>
            {/* CSV UPload Button */}
            {/*New Change :  Change validation as per client need 24 Oct 2024 */}
            {(
                userInfo.userrole === 'SUPER_ADMIN' ||
                userInfo.userrole === 'HEAD_SALES' ||
                userInfo.userrole === 'ADMIN_SALES' ||
                userInfo.userrole === 'RELATIONSHIP_MANAGER'
            ) &&
                <Button size="sm" variant="outline-secondary"
                    onClick={() => { setIsCSVFiles(true) }}
                >
                    Lead CSV Upload
                </Button>

            }

            {/* Pop up for upload CSV functionality */}
            {isCSVFiles && (
                <Modal
                    show={isCSVFiles}
                    onHide={handleClose}
                    size="md"
                    aria-labelledby="CSVUploadFileModal"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="CSVUploadFileModal">
                            Upload CSV File
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Stack gap={4} >
                            <Stack direction='horizontal' gap={2}>
                                <Badge bg='white' text='dark' className='p-2'>
                                    <span>Download Sample Lead CSV</span>
                                </Badge>
                                <Button
                                    variant='success'
                                    size='sm'
                                    href={leadCSV}
                                >
                                    <i className="fs-5 fa-solid fa-file-csv"></i>
                                </Button>
                            </Stack>
                            {isLoader ?
                                (
                                    <div className="d-flex justify-content-center align-items-center" >
                                        <Spinner animation="border" variant="secondary" size="lg" />
                                    </div>
                                ) : (<Form.Control type="file" accept=".csv"
                                    onChange={handleFileChange}
                                />)}
                        </Stack>

                    </Modal.Body>
                    <Modal.Footer>


                        <Button
                            onClick={handleCSVUploadFile}
                            disabled={!selectedFile}
                            variant="success"
                        >
                            Upload
                        </Button>

                        <Button
                            onClick={handleClose}
                            variant="light"
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {/* This modal for show records who's successfully inserted on database */}
            {showTable && <CSVRecordsInfo
                showTable={showTable}
                setShowTable={setShowTable}
                records={records}
                setRecords={setRecords}
            />}
        </React.Fragment >
    )
}

export function UnderwriterCSVUpload({ userInfo, fetchLeads, lead_id, lead_name }) {
    // console.log('userInfo', userInfo);

    //This state for spinner or loader 30 Sept 2024 @ali
    // const [spinner, setSpinner] = useState(false);
    const [isLoader, setIsLoader] = useState(false);


    //This sates for CSV file upload functionality 25 Sept 2024 @ali
    const [isCSVFiles, setIsCSVFiles] = useState(false);
    const handleClose = () => { setIsCSVFiles(false); setSelectedFile(null) };
    const [selectedFile, setSelectedFile] = useState(null);

    //This state for Show table
    const [showTable, setShowTable] = useState(false);
    const [records, setRecords] = useState(null);

    // Handler for file change
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    //Handler for CSV upload and sent data to Server
    const handleCSVUploadFile = async () => {
        if (selectedFile) {

            // Start the loader
            setIsLoader(true);

            //use FormData
            const formData = new FormData();

            // Add the selected file into form data
            formData.append("file", selectedFile);

            // Here we call the Upload UW CSV API
            const response = await SparkApi.uploadUnderwriterCSVFile(formData, lead_id);

            // console.log("Response", response);
            if (response && !response?.errors) {
                //Stop the loader spinner
                setIsLoader(false);

                // Close the modal
                setIsCSVFiles(false);

                // Show message in tost container
                toast.success('CSV file saved successfully');

                // Show records table
                setShowTable(true);
                // set table data
                setRecords(response);


                //make empty the file state
                setSelectedFile(null);

                // Re-fetch all leads
                fetchLeads();

            } else {
                setIsLoader(false)
                setIsCSVFiles(false);

                toast.error(response?.errors ? response?.errors : 'Some error occurred');
                setSelectedFile(null)

                // getLeadsList();
            }
        } else {
            console.log('no files')
        }
    };
    return (
        <React.Fragment>
            {/* CSV UPload Button */}
            {lead_id &&
                // New Change :  Remove validation as per client need 24 Oct 2024
                // userInfo.userrole === 'SUPER_ADMIN' &&
                <Button
                    onClick={() => { setIsCSVFiles(true) }}
                >
                    <i className="fs-4 fa-solid fa-file-arrow-up"></i>
                </Button>}

            {/* Pop up for upload CSV functionality */}
            {isCSVFiles && (
                <Modal
                    show={isCSVFiles}
                    onHide={handleClose}
                    size="md"
                    aria-labelledby="UWBulkUploadModal"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="UWBulkUploadModal">
                            <h3>Upload Underwriter CSV File For</h3>
                            <h4>{lead_name}</h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <Stack gap={4} >
                            <Stack direction='horizontal' gap={2}>
                                <Badge bg='white' text='dark' className='p-2'>
                                    <span>Download Sample Underwriter CSV</span>
                                </Badge>
                                <Button variant='success' href={underwriterCSV}>
                                    <i className="fs-5 fa-solid fa-file-csv"></i>
                                </Button>
                            </Stack>
                            {isLoader ?
                                (
                                    <div className="d-flex justify-content-center align-items-center" >
                                        <Spinner animation="border" variant="secondary" size="lg" />
                                    </div>
                                ) : (<Form.Control type="file" accept=".csv"
                                    onChange={handleFileChange}
                                />)}
                        </Stack>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button
                            onClick={handleCSVUploadFile}
                            disabled={!selectedFile} variant="success"
                        >
                            Upload
                        </Button>

                        <Button onClick={handleClose} variant="light">
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
            }

            {/* This modal for show records who's successfully inserted on database */}
            {showTable && <CSVRecordsInfo
                showTable={showTable}
                setShowTable={setShowTable}
                records={records}
                setRecords={setRecords}
            />}
        </React.Fragment >
    )
}


