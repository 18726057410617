import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react'
import { Button, Col, Row, Table } from 'react-bootstrap';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from 'react-bs-datatable';
import SparkApi from "../api/SparkApi";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import * as constants from '../constants/CONSTANT';
import Image from 'react-bootstrap/Image'
import fileDownload from 'js-file-download';

const RelatedLocationHistory = (props) => {
    const [imagebody, setimagebody] = useState([]);
    const [body, setBody] = useState([]);
    useEffect(() => {

        alllocationhistoryaccess()


    }, []);

    const alllocationhistoryaccess = async () => {
        try {
            let usertrackings = await SparkApi.fetchUsertrackingsWithstaffId(props.parent.id);
            // console.log('usertrackings', usertrackings);
    
            if (usertrackings && usertrackings.length > 0) {
                let arr = [];
                for (let i = 0; i < usertrackings.length; i++) {
                    let obj = usertrackings[i];
                    let files = await SparkApi.fetchFiles(obj.id); // Assuming obj.id is used to fetch files for each usertracking entry
                    
                    if (files && files.length > 0) {
                        for (let j = 0; j < files.length; j++) {
                            let file = files[j];
                            if (file.filetype === 'jpg' || file.filetype === 'jpeg' || file.filetype === 'png') {
                                let result = await SparkApi.downloadFiles(file.id);
                                obj['body'] = window.URL.createObjectURL(result);
                            } else {
                                obj['body'] = '';
                            }
                            // console.log('obj',obj)
                            arr.push({ ...obj }); // Pushing a copy of obj to avoid reference issues
                        }
                    }
                }
    
                setBody(usertrackings,[...arr]);
            } else {
                setBody([]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // Handle the error, e.g., show an error message to the user
            setBody([]);
        }
    };
    

    //('imagebody', imagebody)
    const downloadFile = async (row) => {
        ////('if file download call',row);
        const result = await SparkApi.downloadFiles(row.id);
        if (result) {
            fileDownload(result, row.title);
        }
    }

    const fileSize = (bytes) => {
        var exp = (bytes / 1024) / 1024;
        return exp.toFixed(2) + " MB";
    }

    const renderPreview = (body) => {
        return <Image src={body} thumbnail width="200px" />;

    };


    const header = [
        {
            title: 'Login Time', prop: 'logindatetime', cell: (row) => (moment(row.logindatetime).format('DD-MM-YYYY hh:mm'))
        },
        {
            title: 'Logout Time',
            prop: 'logoutdatetime',
            cell: (row) => (row.logoutdatetime ? moment(row.logoutdatetime).format('DD-MM-YYYY hh:mm A ') : '')
        },
        { title: 'Location', prop: 'location' },
        { title: 'Remarks', prop: 'remarks' },
        {
            title: 'Location Image', prop: 'body', cell: (row) => (
                <>
                    <center><a href='javascript:void(0)' onClick={() => downloadFile(row)}>{renderPreview(row.body)}</a></center>
                </>
            )
        },
    ];


    return (
        <>
            {body ?
                <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
                    initialState: {
                        rowsPerPage: 5
                    }
                }}>
                    <Row className="mb-4">
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-start align-items-start"
                        >
                        </Col>
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-start align-items-start"
                        >
                        </Col>
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                        >
                        </Col>
                    </Row>
                    <Table striped className="related-list-table" responsive="sm">
                        <TableHeader />
                        <TableBody />
                    </Table>
                    <Pagination />
                </DatatableWrapper> : ''}
        </>
    )
};

export default RelatedLocationHistory
