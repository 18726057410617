/* Start updated by Pooja Vaishnav and it will show the list of all the incidents*/

import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import SparkApi from "../api/SparkApi";
import { useLocation } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import moment from "moment";
import { isMobile, MobileView, BrowserView } from 'react-device-detect';

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import InfoPill from "./InfoPill";

const IncidentList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [incidents, setIncidents] = useState();

  useEffect(() => {
    async function init() {
      const incidents = await SparkApi.fetchIncidents();
      if (incidents) {
        setBody(incidents);
        setIncidents(incidents);
      } else {
        setBody([]);
        setIncidents([]);
      }
    }
    init();
  }, []);

  // Create table headers consisting of 4 columns.
  const header = [];
  if (!isMobile) {
    header.push(
      {
        title: "Incident Number",
        prop: "incidentnumber",
        isFilterable: true, isSortable: true,
        cell: (row) => (
          <Link to={"/incidents/" + row.id} state={row}>
            {row.incidentnumber}
          </Link>
        ),
      },
      { title: "Subject", prop: "subject", isFilterable: true },
      { title: "Priority", prop: "priority", isFilterable: true },

      {
        title: "Status",
        prop: "status",
        isFilterable: true, isSortable: true
      },
      { title: "Origin", prop: "origin", isFilterable: true },
      {
        title: "Contact", prop: "contact", isFilterable: true, isSortable: true, cell: (row) => (
          <Link to={"/contacts/" + row.contactid} state={row}>
            {row.contactname}
          </Link>
        ),
      },
      {
        title: "Product", prop: "product", isFilterable: true, isSortable: true, cell: (row) => (
          <Link to={"/products/" + row.productid} state={row}>
            {row.productname}
          </Link>
        ),
      },
      {
        title: "Created Date", prop: "createddate", isSortable: true, isFilterable: true, cell: (row) => (
          <span>
            {moment(row.createddate).format('DD MMM, yyyy')}
          </span>
        )
      },
      {
        title: "Assigned", prop: "ownername", isFilterable: true, isSortable: true, cell: (row) => (
          <Link to={"/users/" + row.ownerid} state={row}>
            {row.ownername}
          </Link>
        ),
      },
    )
  } else {
    header.push(
      {
        title: "Info",
        prop: "subject",
        isFilterable: true, isSortable: true,
        cell: (row) => (
          <div className="mobilecard">
            <Link to={"/incidents/" + row.id} state={row} style={{ fontSize: "1.2rem" }}>
              {row.subject}
            </Link>
          </div>
        ),
      },
    )
  }
  const labels = {
    beforeSelect: " ",
  };

  const createIncident = () => {
    navigate(`/incidents/e`);
  };

  return (
    <Row className="g-0">
      <Col lg={12} className="px-4">
        <DatatableWrapper
          body={body}
          headers={header}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 15,
              options: [5, 10, 15, 20],
            },
          }}
        >
          <Row className="mb-4">
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >

              <Filter />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={5}
              className="d-flex flex-col justify-content-start align-items-center"
            >
              <PaginationOptions labels={labels} />
              <BrowserView>
                <InfoPill left="Total" right={body?.length} />
              </BrowserView>
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={3}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Button
                className="btn-sm"
                variant="outline-primary mx-2"
                onClick={() => createIncident(true)}
              >
                New incident
              </Button>
            </Col>
          </Row>
          {body ? (
            <Table striped className="data-table" responsive="sm">
              <TableHeader />

              <TableBody />
            </Table>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}
          <Pagination />
        </DatatableWrapper>
      </Col>
      <Col lg={2}></Col>
    </Row>
  );
};
export default IncidentList;
/* End updated by Pooja Vaishnav */
