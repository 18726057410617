import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

const PieChart = ({ data2 }) => {
  ChartJS.register(ArcElement, Tooltip);

  const labels = data2?.map((item) => item.label);
  const counts = data2?.map((item) => item.count);

  const data = {
    datasets: [
      {
        data: counts,
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
        ],
      },
    ],
    labels: labels,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Pie Chart',
      },
      datalabels: {
        color: '#fff',
        anchor: 'center',
        align: 'center',
        display: true,
        formatter: (value, context) => {
          const percentage = ((value / context.dataset.total) * 100).toFixed(2);
          return percentage + '%';
        },
      },
    },
  };

  return (
    <div style={{ height: '300px', width: '500px',marginLeft : '120px' }}>
      <Pie data={data} options={options} />
    </div>
  );
};

export default PieChart;
