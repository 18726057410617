import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import SparkApi from "../../api/SparkApi";
import { MentionsInput, Mention } from "react-mentions";
import jwt_decode from "jwt-decode";
import mentionsInputStyle from "./mentionsInputStyles";
import parse from 'html-react-parser'

const Chat = ({ parentid }) => {
  const [inputTxt, setInputTxt] = useState("");
  const [messages, setMessages] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userInfo, setUserInfo] = useState(false);

  useEffect(() => {
    setUserInfo(jwt_decode(localStorage.getItem("token")));
    messageList();
    fetchUsers();
  }, [parentid]);

  const fetchUsers = () => {
    async function init() {
      let users = await SparkApi.fetchUsers();

      if (users && users?.length > 0) {
        let result = users.map((a) => {
          return { id: a.id, display: a.username };
        });
        ////("result", result);
        setUserList(result);
      } else {
        setUserList([]);
      }
    }
    init();
  };

  const messageList = () => {
    async function init() {
      let tasks = await SparkApi.fetchMessages(parentid);
      ////("tasks", tasks);
      ////(tasks);
      if (tasks && tasks?.length > 0) {
        let modifiedMesgs = [];
        tasks.forEach(function (tsk) {
          tsk.description = formatMessage(tsk.description);
          modifiedMesgs.push(tsk);
        });

        setMessages(modifiedMesgs);
      } else {
        setMessages([]);
      }
    }
    init();
  };

  const formatMessage = (mystr) => {
    
    let oldStr = mystr;
    mystr = mystr.substring(1);
    var arry = mystr.split(" @");
    var returnArr = [];

    for (var a of arry) {
      var obj = {};
      var value = a.substring(a.lastIndexOf("[") + 1, a.lastIndexOf("]"));
      var key = a.substring(a.lastIndexOf("(") + 1, a.lastIndexOf(")"));
      obj[`username`] = value;
      obj[`id`] = key;
      if(key)
        returnArr.push(obj);
    }
    ////(returnArr)
    for (var a of returnArr) {
      ////(`@[${a.username}](${a.id})`)
      oldStr = oldStr.replace(`@[${a.username}](${a.id})`, `<a href="/users/${a.id}">${a.username}</a>`);
    }

    return oldStr;
  };

  const inputChange = (e) => {
    ////(e);
    setInputTxt(e.target.value);
  };

  const sendMessage = async (e) => {
    if (inputTxt.trim().length > 0) {
      const result = await SparkApi.createMessage({
        parentid: parentid,
        description: inputTxt,
      });
      if (result) {
        messageList();
        setInputTxt("");
      }
    }
  };

  const deleteMessage = async (recid) => {
    ////("dleete");
    const result = await SparkApi.deleteMessage(recid);
    messageList();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  return (
    <>
      <Row className="view-form-header align-items-center">
        <Col
          lg={12}
          className="d-flex align-items-center justify-content-between"
        >
          <span style={{ border: "0", padding: ".2rem" }}>
            <i className="fa-solid fa-comment" style={{ color: "#ffbe00" }}></i>{" "}
            Notes & Messages
          </span>
          <i className="fa-solid fa-rotate-right"></i>
        </Col>
      </Row>
      <Row className="">
        <div className="chat pb-2">
          <div className="w-100 mt-2  chat-input-group text-right">
            {/* <input
           ref={inputRef}
           type="text"
           style={{ height: "2.5rem", width: "100%" }}
           value={inputTxt}
           onChange={inputChange}
           className="chat-input"
           onKeyDown={handleKeyDown} 
         /> */}

            {/* <TextInput options={userList} 
style={{width: "100%" }}
value={inputTxt}
onChange={inputChange}
className="chat-input"
// onKeyDown={handleKeyDown} 

/> */}

            <MentionsInput
              value={inputTxt}
              onChange={inputChange}
              style={mentionsInputStyle}
              placeholder="Share your message..."
            >
              <Mention
                trigger="@"
                data={userList}
                style={{ backgroundColor: "#cee4e5", "paddingTop" : ".2rem" , "paddingBottom" : ".2rem", left: "-.2rem"}}
              />
            </MentionsInput>

            <button
              className="sendbtn mt-2"
              type="button"
              onClick={sendMessage}
            >
              <i className="fa-regular fa-paper-plane"></i> Send
            </button>
          </div>
          <div style={{maxHeight: "50rem", overflowY: "auto"}}>
            {messages &&
              messages.map((item) => (item.msgtype !== 'SYSTEM' && 
                <div
                  style={{
                    paddingLeft: ".2rem",
                    paddingTop: ".5rem",
                    backgroundColor: "beige",
                    borderRadius: "5px",
                    padding: "1rem",
                    marginTop: ".5rem",
                    marginBottom: ".5rem",
                  }}
                >
                  <div className="d-flex align-items-center justify-content-between">{item.msgtype}
                    <div
                      style={{
                        fontSize: "smaller",
                        borderBottom: "1px solid #17191c33",
                        paddingBottom: ".2rem",
                        marginBottom: ".5rem",
                      }}
                    >
                      <span
                        className="d-inline"
                        style={{
                          border: "none",
                          color: "#e6e6e6",
                          fontSize: "1rem",
                          color: "#645721",
                        }}
                      >
                        {item.createdbyname}{" "}
                      </span>{" "}
                      <span
                        className="d-inline"
                        style={{
                          paddingLeft: "1rem",
                          color: "#645721",
                          fontSize: ".8rem",
                          border: "none",
                        }}
                      >
                        {item.date}
                      </span>
                    </div>{" "}
                    {item.createdbyid === userInfo.id && (
                      <i
                      className="fa-solid fa-trash-can"
                        style={{ color: "tomato", cursor: "pointer" }}
                        onClick={(e) => deleteMessage(item.id)}
                      ></i>
                    )}
                  </div>
                  <div>{parse(item.description)} </div>
                </div>
              ))}

            {(!messages || messages.length === 0) && (
              <div
                style={{
                  paddingLeft: ".2rem",
                  paddingTop: ".5rem",
                  backgroundColor: "beige",
                  borderRadius: "5px",
                  padding: "1rem",
                  marginTop: ".5rem",
                  marginBottom: ".5rem",
                }}
              >
                No messages
              </div>
            )}
          </div>
        </div>
      </Row>
    </>
  );
};
export default Chat;
