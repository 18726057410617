import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import SparkApi from "../api/SparkApi";
import PubSub from "pubsub-js";
import CityState from "../constants/CityState.json";
import Select from "react-select";

const AccountEdit = (props) => {
  const [validated, setValidated] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [options, setOptions] = useState([]);
  const [name, setName] = useState("");
  const [state, setState] = useState({});
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [accountname, seAccounttName] = useState("");
  const [show, setshow] = useState(false);


  useEffect(() => {
    let fetchedAccounts = [];
    accounts.map((item) => {
      fetchedAccounts.push({
        label: item.name,
        population: 4780127,
        capital: "Montgomery",
        region: "South",
      });
    });
    setOptions(fetchedAccounts);

    //////console.log('CityState',CityState)
    let st = [];
    CityState.map((item) => {
      //////console.log(item.state,item.id);
      var obj = {};
      obj.value = item.state;
      obj.label = item.state;
      //////console.log(" obj.label >"+ obj.label)
      st.push(obj);
    });
    setState(st);
  }, [accounts]);

  const location = useLocation();
  const navigate = useNavigate();
  //const [account, setaccount] = useState(location.state);
  const [account, setAccount] = useState(location.state ? location.state : {});
  ////console.log("Account name => ",account.name)
  {
    account.id != null && name === "" ? setName(account.name) : <h1>{null}</h1>;
  }

  const handleChange = (e) => {
    setAccount({ ...account, [e.target.name]: e.target.value });
  };

  const handleState = (e) => {
    let filteredCities = [];
    CityState.forEach(function (obj) {
      if (obj.state === e.value) {
        filteredCities.push({
          label: obj.name,
          value: obj.name,
        });
      }
    });
    setCities(filteredCities);
    setAccount({ ...account, state: e.value });
  };

  const handleSelectListChange = (value, name) => {
    ////console.log("account:", account);
    setAccount({ ...account, [name]: value.value });
    setSelectedCity(value.value);
  };
  const checkRequredFields = () => {
    if (
      account.name &&
      account.name.trim() !== "" &&
      account.phone &&
      account.phone.trim() !== "" &&
      account.email &&
      account.email.trim() !== "" &&
      account.website &&
      account.website.trim() !== ""

    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (checkRequredFields()) {
      setValidated(true);
      setshow(true)
      return;
    }

    //========= Logic to perform Create or Edit ======
    let result = {};
    if (account.id) {
      result = await SparkApi.saveAccount(account);
      ////console.log(result)
      if (result.success) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/accounts/${account.id}`, { state: account });
      }
    } else {
      result = await SparkApi.createAccount(account);
      if (result) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/accounts/${result.id}`, { state: result });
      }
    }
  };

  const handleCancel = () => {

    if (account.id) {
      navigate("/accounts/" + account.id, { state: account });
    } else {
      navigate("/accounts/");
    }

  };

  return (
    <Container className="view-form">
      <Row >
        <Col></Col>
        <Col lg={8} className="pb-1 pt-2">
          <Link className="nav-link" to="/accounts">
            Home <i className="fa-solid fa-chevron-right"></i> <div style={{ color: "#23468c", display: "inline" }}>Accounts</div>
          </Link>
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col></Col>
        <Col lg={8}>
          <Form
            className="mt-3"
            onSubmit={handleSubmit}

          >
            <Row className="view-form-header align-items-center">

              <Col lg={8}>
                {

                  account.name === undefined ? <h6>Create Account</h6> : <h6>Edit Account</h6>
                }
                <h5>{name}</h5>

              </Col>
              <Col lg={4} className="d-flex justify-content-end">
                <Button className="btn-sm mx-2" onClick={handleSubmit}>
                  Save
                </Button>
                <Button
                  className="btn-sm"
                  variant="danger"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
            <Row className="ibs-edit-form">
              <span>
                <Alert
                  variant="danger"
                  show={show}
                  className="error-alert my-3"
                >
                  Please provide required fields
                </Alert>
              </span>
              <Row lg={12} className="mb-4">
                <Col lg={6}>
                  <Form.Group className="mx-3" >
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicName"
                    >
                      Account Name
                    </Form.Label>
                    <Form.Control
                      style={{ height: "37px" }}
                      required
                      type="text"
                      name="name"
                      placeholder="Enter Name"
                      value={account.name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>


                <Col lg={6}>
                  <Form.Group className="mx-3" >
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicPhone"
                    >
                      Phone
                    </Form.Label>
                    <Form.Control
                      required
                      style={{ height: "37px" }}
                      type="text"
                      name="phone"
                      maxLength="10"
                      placeholder="Enter phone"
                      value={account.phone}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" >
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicEmail"
                    >
                      Email
                    </Form.Label>
                    <Form.Control
                      required
                      style={{ height: "37px" }}
                      type="email"
                      name="email"
                     
                      value={account.email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" >
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicwebsite"
                    >
                      Website
                    </Form.Label>
                    <Form.Control
                      style={{ height: "37px" }}
                      required
                      type="text"
                      name="website"
                      placeholder="Enter website"
                      value={account.website}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" >
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicState"
                    >
                      State
                    </Form.Label>
                    <Select

                      placeholder="State"
                      defaultValue={{
                        label: account.state,
                        value: account.state,
                      }}
                      onChange={handleState}
                      options={state}
                    //value={salutationValue}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" >
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCity"
                    >
                      City
                    </Form.Label>
                    <Select
                      // required
                      // className="custom-select"
                      options={cities}
                      onChange={(e) => {
                        handleSelectListChange(e, "city");
                      }}
                      name="city"
                      defaultValue={{ label: account.city, value: account.city }}
                      placeholder="Enter City"
                    />
                  </Form.Group>
                </Col>


                <Col lg={6}>
                  <Form.Group className="mx-3" >
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicStreet"
                    >
                      Street
                    </Form.Label>
                    <Form.Control
                      style={{ height: "37px" }}
                      type="text"
                      name="street"
                      placeholder="Enter Street"
                      value={account.street}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" >
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicPin"
                    >
                      Pincode
                    </Form.Label>
                    <Form.Control
                      style={{ height: "37px" }}
                      type="text"
                      name="pincode"
                      placeholder="Enter Pincode"
                      value={account.pincode}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" >
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCountry"
                    >
                      Country
                    </Form.Label>
                    <Form.Control
                      style={{ height: "37px" }}
                      type="text"
                      name="country"
                      placeholder="Enter country"
                      value={account.country}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Row></Row>
              </Row>
            </Row>
          </Form>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};
export default AccountEdit;